import {
  validateEmail,
  validateRE,
  validatePhoneNumber,
  checkForDomainTypo,
} from "sites-common/utils/validations";

function validateProfile(
  country,
  info,
  amount,
  currency,
  validateInfoOnly = false,
  recurringDonationSection,
  enableLastname
) {
  const errors = [];
  const warnings = [];
  const { identificationType, identificationNumber, memberId } = info;

  if (!validateInfoOnly) {
    if (!amount || amount < 1) {
      errors.push("error-amount");
      return { errors, warnings };
    }
  }
  const formFields = [
    { f: "name", e: "error-name" },
    { f: "email", e: "error-email" },
    { f: "phone", e: "error-phone" },
    { f: "city", e: "error-city" },
    { f: "address", e: "error-address" },
    { f: "postalCode", e: "error-pincode" },
  ];

  if (enableLastname && country === "us") {
    formFields.push({ f: "lastName", e: "error-lastName" });
  }

  formFields.map((x) => {
    if (!(x.f in info && info[x.f])) {
      errors.push(x.e);
    }
    return false;
  });

  if (info.email) {
    if (!validateEmail(info.email)) {
      errors.push("error-email");
    } else {
      const shouldEmailIdBe = checkForDomainTypo(info.email);
      if (shouldEmailIdBe) {
        warnings.push({
          code: "warning-email-domain",
          message: `Should it be ${shouldEmailIdBe}`,
        });
      }
    }
  }

  if (info.phone && !validatePhoneNumber(info.phone)) {
    errors.push("error-phone");
  }

  if (info.city && !info.country) {
    errors.push("error-country");
  }

  if (!validateInfoOnly) {
    if (currency === "INR") {
      if (amount) {
        if (amount < 10) {
          errors.push("error-in-amount-min");
        }
        if (amount > 10000000) {
          errors.push("error-in-amount-max");
        }
      }
    }
  }
  if (country === "in") {
    if (!info.citizenship) {
      errors.push("error-in-citizenship");
    }

    if (
      memberId &&
      !validateRE("^([a-zA-Z]{6}[0-9]{3}|[HABhab]{1}[0-9]{8})$", memberId)
    ) {
      errors.push("error-in-abhyasiid");
    }

    if (identificationType) {
      if (identificationNumber) {
        switch (identificationType) {
          case "Pancard":
            if (
              !validateRE("^[A-Za-z]{5}[0-9]{4}[A-Za-z]$", identificationNumber)
            ) {
              errors.push("error-in-pan");
            }
            break;
          case "Aadhar Card":
            if (!validateRE("^[0-9]{12}$", identificationNumber)) {
              errors.push("error-in-aadhar");
            } else {
              warnings.push("warning-in-identity");
            }
            break;
          default:
            if (identificationNumber.length < 6) {
              errors.push("error-in-identification-number");
            } else {
              warnings.push("warning-in-identity");
            }
            break;
        }
      } else {
        warnings.push("warning-in-pan");
      }
    }
  }
  if (recurringDonationSection) {
    const { donation_type } = recurringDonationSection;
    switch (donation_type) {
      case "ONLINE":
        {
          const { start_date, account_type } =
            recurringDonationSection.onlineSectionDetails;
          if (!account_type) {
            errors.push("error-online-account-type");
          }
          if (!start_date) {
            errors.push("error-online-start-date");
          }
        }
        break;

      case "OFFLINE":
        {
          const { payment_option } =
            recurringDonationSection.offlineSectionDetails;
          if (!payment_option) errors.push("error-offline-payment-option");
          switch (payment_option) {
            case "STANDING_INSTRUCTION":
              {
                const { payment_mode } =
                  recurringDonationSection.offlineSectionDetails
                    .standingInstructionDetails;
                if (!payment_mode) errors.push("error-offline-payment-option");
                switch (payment_mode) {
                  case "INTERNET_BANKING":
                    {
                      const { donor_bank, donor_ifsc_code, standing_date } =
                        recurringDonationSection.offlineSectionDetails
                          .standingInstructionDetails.netBankingDetails;
                      if (!donor_bank)
                        errors.push("error-offline-net-banking-donor-bank");
                      if (!donor_ifsc_code)
                        errors.push("error-offline-net-banking-donor-ifsc");
                      if (!standing_date)
                        errors.push("error-offline-net-banking-standing-date");
                    }
                    break;

                  case "WALLET":
                    {
                      const { wallet_mobile, standing_date } =
                        recurringDonationSection.offlineSectionDetails
                          .standingInstructionDetails.walletDetails;
                      if (!wallet_mobile)
                        errors.push("error-offline-wallet-mobile");
                      if (!standing_date)
                        errors.push("error-offline-wallet-standing-date");
                    }
                    break;

                  default:
                    break;
                }
              }
              break;

            default:
              break;
          }
        }
        break;

      default:
        break;
    }
  }
  return { errors, warnings };
}

export default validateProfile;
