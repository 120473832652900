import { useFetchJson } from "gatsby-plugin-hfn-profile/fetch-url";
import currencyApi from "../service-wrappers/currency-api";

function useCurrencyRates(base) {
  const { data } = useFetchJson(`currency${base}`, currencyApi(base), {});
  if (data) {
    return {
      currencyOptions: Object.keys(data.rates).sort((a, b) => a > b),
      rates: data.rates,
    };
  }
  return { currencyOptions: [base], rates: { [base]: 1 } };
}

export default useCurrencyRates;
