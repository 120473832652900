import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchJsonIfNeeded as A } from "gatsby-plugin-hfn-profile/state/actions";
import { selectorJson as S } from "gatsby-plugin-hfn-profile/state/selectors";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { donationServiceConfig } = getMandatoryEnv(["donationServiceConfig"]);

function useDonationConf() {
  const key = "donation-conf";
  const dispatch = useDispatch();
  const { isLoaded, isFetching, data } = useSelector((state) => S(state, key));

  useEffect(() => {
    const { staticConf: url } = donationServiceConfig;
    if (!isFetching && !isLoaded && url) {
      dispatch(A(key, url, {}));
    }
  }, [isFetching, isLoaded, dispatch]);

  return {
    isLoaded,
    conf: isLoaded ? data : {},
  };
}

export default useDonationConf;
