import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const R = ({ pre, icon }) => (
  <>
    {pre}
    <FontAwesomeIcon sx={{ fontSize: "0.9em" }} icon={icon} />
  </>
);
function CurrencySymbol({ to }) {
  switch (to) {
    case "INR":
      return <R pre="" icon="rupee-sign" />;
    case "CAD":
      return <R pre="CA" icon="dollar-sign" />;
    case "USD":
      return <R pre="US" icon="dollar-sign" />;
    default:
      return to;
  }
}

export default CurrencySymbol;
