import { Switch } from "theme-ui";
import React from "react";

const MonthlyRecurringCheckbox = (props) => (
  <Switch
    {...props}
    label="Setup monthly recurring donation"
    sx={{ color: "text" }}
    disabled
  />
);

export default React.memo(MonthlyRecurringCheckbox);
