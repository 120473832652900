/** @jsx jsx */
import React, {
  useCallback,
  useState,
  useMemo,
  useContext,
  useEffect,
} from "react";
import useMyState from "sites-common/hooks/useMyState";
import { validateRE } from "sites-common/utils/validations";
import HtmlDiv from "sites-common/components/HtmlDiv";
import { Pivot, PivotItem, Stack, TextField } from "office-ui-fabric-react";

import { jsx } from "theme-ui";
import { useQueryParam, StringParam } from "use-query-params";

import PropTypes from "prop-types";
import DonationComponent from "../components/DonationComponent";

const eventCallbackTriggers = () => {};

const colorKeys = [
  "background",
  "text",
  "primary",
  "secondary",
  "errortext",
  "errorbg",
  "muted",
  "highlight",
  "background2",
];

const decodeColors = (colorString) => {
  const ret = {};
  colorString
    .split(":")
    .filter((x, xidx) => xidx < colorKeys.length)
    .forEach((x, xidx) => {
      ret[colorKeys[xidx]] = `#${x}`;
    });
  return ret;
};

const encodeColors = (colors) => {
  return colorKeys.map((c) => colors[c].replace("#", "")).join(":");
};

// colortext = "000000",
// colorprimary = "228b22",
// colorsecondary = "006400",
// colorbackground = "",
// colormuted = "f6f6f6",
// colorhighlight = "efeffe",
// colorerrorbg = "fed4d4",
// colorerrortext = "ba0000",
// colorbackground2 = "f0f0f0",

const isBrowser = () => typeof window !== "undefined";
const MyContext = React.createContext({});

const DF = React.memo(({ name }) => {
  const context = React.useContext(MyContext);
  const { vals, updateKVE } = context;

  return (
    <div sx={{}}>
      <div variant="subtitle">{name}</div>
      <input
        type="text"
        value={vals[name]}
        onChange={(e) => updateKVE(e.target.value, name)}
      />
      <div sx={{ height: "20px" }}>
        {!validateRE("#[0-9abcdef]{6}", vals[name]) ? "wrong value" : ""}
      </div>
    </div>
  );
});

const getColors = (colors) => {
  const colors1 = {};
  Object.keys(colors).forEach((c1) => {
    colors1[c1] = validateRE("#[0-9abcdef]{6}", colors[c1])
      ? colors[c1]
      : "#000000";
  });
  return colors1;
};

const ColorSchemeEditor = () => (
  <div sx={{ px: 2, bg: "#ededed" }}>
    <h3>Color Scheme</h3>

    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
      <DF name="text" />
      <DF name="background" />

      <DF name="primary" />
      <DF name="secondary" />

      <DF name="errorbg" />
      <DF name="errortext" />

      <DF name="background2" />

      <DF name="muted" />
      <DF name="highlight" />
    </Stack>
  </div>
);

const BtnStyles = ({ initialBtnText }) => {
  const {
    styleText,
    setStyleText,
    btnClass,
    setBtnClass,
    btnStyle,
    setBtnStyle,
    btnText,
    setBtnText,
  } = useContext(MyContext);
  useEffect(() => {
    setBtnText(initialBtnText);
  }, [initialBtnText, setBtnText]);
  return (
    <div>
      <div>
        <small>
          <i>
            One can use an existing button class or set button styles or use
            both of them on the sign in button to match the hosting page.
          </i>{" "}
        </small>{" "}
      </div>
      <TextField
        label="Example CSS Styles in Hosting Page"
        styles={{ field: { backgroundColor: "#ededed" } }}
        borderless
        multiline
        autoAdjustHeight
        name="styleText"
        value={styleText}
        onChange={(e) => setStyleText(e.target.value)}
      />

      <Stack horizontal tokens={{ childrenGap: 10 }}>
        <TextField
          label="Button Class"
          name="btnClass"
          value={btnClass}
          onChange={(e) => setBtnClass(e.target.value)}
        />
        <TextField
          multiline
          rows={1}
          autoAdjustHeight
          label="Button Inline Style"
          name="btnStyle"
          value={btnStyle}
          onChange={(e) => setBtnStyle(e.target.value)}
        />
        <TextField
          label="Text on Button"
          name="btnText"
          value={btnText}
          onChange={(e) => setBtnText(e.target.value)}
        />
      </Stack>
    </div>
  );
};

BtnStyles.propTypes = {
  initialBtnText: PropTypes.string.isRequired,
};

const Embed = () => {
  const [c] = useQueryParam("c", StringParam);
  const [d] = useQueryParam("d", StringParam);
  const [t] = useQueryParam("t", StringParam);
  const [p] = useQueryParam("p", StringParam);

  const [projectId, setProjectId] = useState(p || "heartfulness-general-fund");

  const [colors, { updateKVE }] = useMyState(
    decodeColors(
      c || `ffffff:000000:228b22:006400:ba0000:fed4d4:f6f6f6:efeffe:f0f0f0`
    )
  );

  const [donationId, setDonationId] = useState(d || 9);
  const [titleText, setTitleText] = useState(t || "Heartfulness General Fund");
  const [btnText, setBtnText] = useState("Donate Now");
  const [styleText, setStyleText] = useState(
    `.abc-button { font-size: 1.2rem; background: darkgreen; color: white; } .abc-button:hover { opacity: 0.5;} .xyz-button{font-size: 1.1rem; font-weight: 800; color: cyan; background: #3456ae;}`
  );
  const [btnStyle, setBtnStyle] = useState(
    "padding: 20px;margin: 4px 2px;border-radius: 18px;"
  );
  const [btnClass, setBtnClass] = useState("abc-button");

  const colors1 = useMemo(() => getColors(colors), [colors]);

  const props = {
    colors: colors1,
    donationId,
    titleText,
    projectId,
    paymentSuccessUrl: isBrowser() ? window.location.href : "",
    paymentFailureUrl: isBrowser() ? window.location.href : "",
  };

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers(projectId, event),
    [projectId]
  );

  return (
    <MyContext.Provider
      value={{
        vals: colors,
        updateKVE,
        styleText,
        setStyleText,
        btnStyle,
        setBtnStyle,
        btnClass,
        setBtnClass,
        btnText,
        setBtnText,
      }}
    >
      <div sx={{ p: 1 }}>
        <h2>HFN Widgets</h2>

        <Pivot>
          <PivotItem headerText="Donations Widget">
            <Stack tokens={{ childrenGap: 10 }}>
              <ColorSchemeEditor />
              <Stack.Item sx={{ p: 2, bg: "#dcdcdc" }}>
                <h3>Donation Initiative Configuration</h3>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <TextField
                    label="Title Text"
                    name="titleText"
                    value={titleText}
                    onChange={(e) => setTitleText(e.target.value)}
                  />
                  <TextField
                    label="Donation Id"
                    name="donationId"
                    value={donationId}
                    onChange={(e) => setDonationId(e.target.value)}
                  />
                  <TextField
                    label="Project Tag"
                    name="projectId"
                    value={projectId}
                    onChange={(e) => setProjectId(e.target.value)}
                  />
                </Stack>
              </Stack.Item>
              <div sx={{ p: 2, bg: "#ededed" }}>
                <h3>Donate Button Styles</h3>
                <BtnStyles initialBtnText="Donate Now" />
              </div>
              <div sx={{ px: 2, bg: "#dcdcdc" }}>
                <h3>Button and Widget Design</h3>

                <Stack>
                  <Stack.Item>
                    <div sx={{ mx: 2 }}>
                      <HtmlDiv
                        htmlString={`<div>
                          <style>${styleText}</style>
                          <button class="${btnClass}" style="cursor:pointer;${btnStyle}">${btnText}</button>
                          </div>
                          `}
                      />
                    </div>
                    <div
                      sx={{ border: "solid 2px grey", m: 2, maxWidth: "600px" }}
                    >
                      <DonationComponent
                        {...props}
                        eventCallback={eventCallback}
                        noModal
                      />
                    </div>
                  </Stack.Item>

                  <Stack.Item>
                    <div>
                      <h3>Script in Head Section</h3>
                      <code>
                        &lt;head&gt;
                        <br />
                        <div sx={{ ml: 4 }}>
                          ...
                          <br />
                          &lt;script
                          src=&quot;https://widgets.heartfulness.org/embed/widget.js&quot;&gt;&lt;/script&gt;
                          <br />
                          ...
                          <br />
                        </div>
                        &lt;/head&gt;
                      </code>
                      <h3>Button for Donation</h3>
                      <code>
                        &lt;button
                        <br />
                        <div sx={{ ml: 4 }}>
                          data-bs-toggle=&quot;modal&quot;
                          <br />
                          data-bs-target=&quot;#hfn-donation-widget&quot;
                          <br />
                          data-title=&quot;{titleText}&quot;
                          <br />
                          data-donation-id=&quot;{donationId}&quot;
                          <br />
                          data-project-id=&quot;{projectId}&quot;
                          <br />
                          data-color-background=&quot;
                          {colors.background.replace("#", "")}
                          &quot;
                          <br />
                          data-color-primary=&quot;
                          {colors.primary.replace("#", "")}
                          &quot;
                          <br />
                          data-color-secondary=&quot;
                          {colors.secondary.replace("#", "")}&quot;
                          <br />
                          data-color-text=&quot;{colors.text.replace("#", "")}
                          &quot;
                          <br />
                          data-color-errortext=&quot;
                          {colors.errortext.replace("#", "")}&quot;
                          <br />
                          data-color-errorbg=&quot;
                          {colors.errorbg.replace("#", "")}
                          &quot;
                          <br />
                          data-color-background2=&quot;
                          {colors.background2.replace("#", "")}
                          &quot;
                          <br />
                          data-color-muted=&quot;{colors.muted.replace("#", "")}
                          &quot;
                          <br />
                          data-color-highlight=&quot;
                          {colors.highlight.replace("#", "")}&quot;
                          <br />
                          class=&quot;{btnClass}&quot;
                          <br />
                          style=&quot;{btnStyle}&quot;
                        </div>
                        &gt; {btnText} &lt;/button&gt;
                        <br />
                      </code>

                      <ol sx={{ my: 4 }}>
                        <li>Include the script in the head section</li>
                        <li>
                          The attributes
                          data-bs-target=&quot;#hfn-donation-widget&quot; and
                          data-bs-toggle=&quot;modal&quot; needs to be added to
                          the Donate button. The data attributes need to be
                          added as follows:
                        </li>
                        <li>
                          data-donation-id=&quot;9&quot; (this is the donation
                          id for particular initiative as received by accounts
                          team)
                        </li>
                        <li>
                          data-project-id=&quot;heartfulness-general-fund&quot;
                          (please set this attribute as received by accounts
                          team)
                        </li>
                        <li>
                          data-title=&quot;set it to whatever you want as the
                          header on the donation widget&quot;
                        </li>
                        <li>
                          Color Scheme (optional) -- The color value needs to be
                          a six character hexcode without any #. Either some of
                          them can be set or all of them can be set. For the
                          values which are not set, the default values would be
                          used. The valid color attributes (with their default
                          values, if not set) are:
                          data-color-background=&quot;ffffff&quot;
                          data-color-primary=&quot;228b22&quot;
                          data-color-secondary=&quot;006400&quot;
                          data-color-text=&quot;000000&quot;
                          data-color-errortext=&quot;ba0000&quot;
                          data-color-errorbg=&quot;fed4d4&quot;
                          data-color-background2=&quot;f0f0f0&quot;
                          data-color-muted=&quot;f6f6f6&quot;
                          data-color-highlight=&quot;efeffe&quot;
                        </li>
                      </ol>

                      <h3 sx={{ mt: 4 }}>
                        Donation Page Direct Link (without using any scripts)
                      </h3>
                      <div sx={{ m: 4 }}>
                        <code>
                          <a
                            target="_blank"
                            href={encodeURI(
                              `https://donations.heartfulness.org/embedded?d=${donationId}&amp;t=${titleText}&amp;c=${encodeColors(
                                colors
                              )}&amp;p=${projectId}`
                            )}
                            rel="noreferrer"
                          >
                            {encodeURI(
                              `https://donations.heartfulness.org/embedded?d=${donationId}&amp;t=${titleText}&amp;c=${encodeColors(
                                colors
                              )}&amp;p=${projectId}`
                            )}
                          </a>
                        </code>
                      </div>
                    </div>
                  </Stack.Item>
                </Stack>
              </div>
            </Stack>
          </PivotItem>
          <PivotItem headerText="Sign-In Button / Avatar with My Profile">
            <Stack tokens={{ childrenGap: 10 }}>
              <ColorSchemeEditor />
              <div sx={{ p: 2, bg: "#ededed" }}>
                <h3>Sign-In Button Styles</h3>
                <BtnStyles initialBtnText="Sign-In" />
              </div>

              <Stack.Item>
                <div sx={{ p: 2, bg: "#dcdcdc" }}>
                  <h3> Sign-In Button / Avatar </h3>

                  <HtmlDiv
                    htmlString={`<div>
                          <style>${styleText}</style>
                          <button class="${btnClass}" style="cursor:pointer;${btnStyle}">${btnText}</button>
                          </div>
                          `}
                  />
                </div>

                <div sx={{ m: 3 }}>
                  <h3>Script in Head Section</h3>
                  <code>
                    &lt;head&gt;
                    <br />
                    <div sx={{ ml: 4 }}>
                      ...
                      <br />
                      &lt;script
                      src=&quot;https://widgets.heartfulness.org/embed/widget.js&quot;&gt;&lt;/script&gt;
                      <br />
                      ...
                      <br />
                    </div>
                    &lt;/head&gt;
                  </code>
                  <h3>Button (Sign-in / Avatar) in Body Section</h3>
                  <code>
                    &lt;div
                    <br />
                    <div sx={{ ml: 4 }}>
                      class=&quot;hfn-auth-button&quot;
                      <br />
                      data-color-background=&quot;
                      {colors.background.replace("#", "")}
                      &quot;
                      <br />
                      data-color-primary=&quot;{colors.primary.replace("#", "")}
                      &quot;
                      <br />
                      data-color-secondary=&quot;
                      {colors.secondary.replace("#", "")}&quot;
                      <br />
                      data-color-text=&quot;{colors.text.replace("#", "")}&quot;
                      <br />
                      data-color-errortext=&quot;
                      {colors.errortext.replace("#", "")}&quot;
                      <br />
                      data-color-errorbg=&quot;{colors.errorbg.replace("#", "")}
                      &quot;
                      <br />
                      data-color-background2=&quot;
                      {colors.background2.replace("#", "")}
                      &quot;
                      <br />
                      data-color-muted=&quot;{colors.muted.replace("#", "")}
                      &quot;
                      <br />
                      data-color-highlight=&quot;
                      {colors.highlight.replace("#", "")}&quot;
                      <br />
                      data-signin-btn-style=&quot;{btnStyle}&quot;
                      <br />
                      data-signin-btn-class=&quot;{btnClass}&quot;
                    </div>
                    /&gt;
                    <br />
                  </code>
                </div>
              </Stack.Item>
            </Stack>
          </PivotItem>
        </Pivot>
      </div>
    </MyContext.Provider>
  );
};

export default Embed;
