import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { noop } from "sites-common/utils/lodash";
import { Box } from "theme-ui";
import { FlexColumn } from "../../../index";
import { MonthlyRecurringSwitch, OnlineSection } from "../index";

export const useOnlyOnlineRecurringDonation = (args = {}) => {
  const {
    isMonthlyRecurringDonation = true,
    onlineSectionDetails = {},
    onChange = noop,
  } = args;

  const handleOnlineRecurringSectionChange = useCallback(
    (update) => {
      onChange({
        isMonthlyRecurringDonation,
        onlineSectionDetails,
        donation_type: "ONLINE",
        ...update,
      });
    },
    [onChange, isMonthlyRecurringDonation, onlineSectionDetails]
  );

  return {
    isMonthlyRecurringDonation,
    onlineSection: React.useMemo(
      () => ({
        ...onlineSectionDetails,
        onChange: (onlineSectionDetail) =>
          handleOnlineRecurringSectionChange({
            onlineSectionDetails: onlineSectionDetail,
          }),
      }),
      [handleOnlineRecurringSectionChange, onlineSectionDetails]
    ),
    montlhyRecurringSwitch: React.useMemo(
      () => ({
        defaultChecked: isMonthlyRecurringDonation,
        onChange: ({ target: { checked: isMonthlyRecurringDonationCheck } }) =>
          handleOnlineRecurringSectionChange({
            isMonthlyRecurringDonation: isMonthlyRecurringDonationCheck,
          }),
      }),
      [isMonthlyRecurringDonation, handleOnlineRecurringSectionChange]
    ),
  };
};

const OnlyOnline = ({
  isMonthlyRecurringDonation,
  montlhyRecurringSwitch,
  onlineSection,
  country,
}) => (
  <FlexColumn gap="13px" sx={{ color: "text", my: 2 }}>
    <MonthlyRecurringSwitch {...montlhyRecurringSwitch} />
    {country === "in" && (
      <Box
        sx={{
          fontSize: "13px",
          backgroundColor: "highlight",
          padding: "7px",
          margin: "5px 0px !important",
        }}
      >
        Note: To set up monthly donations for overseas citizen, Contact
        info.accounts@sahajmarg.org
      </Box>
    )}
    {isMonthlyRecurringDonation && (
      <OnlineSection country={country} {...onlineSection} />
    )}
  </FlexColumn>
);

OnlyOnline.propTypes = {
  isMonthlyRecurringDonation: PropTypes.bool,
  montlhyRecurringSwitch: PropTypes.shape({}),
  onlineSection: PropTypes.shape({}),
  country: PropTypes.string,
};

OnlyOnline.defaultProps = {
  isMonthlyRecurringDonation: true,
  montlhyRecurringSwitch: {},
  onlineSection: {},
  country: "in",
};

export default React.memo(OnlyOnline);
