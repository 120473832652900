import { useEffect } from "react";

export default function FormWithSuccessFailure({
  renderSuccess,
  renderFailure,
  isSuccess,
  isFailure,
  isCancel,
  onLoadSuccess,
  onLoadFailure,
  onLoadForm,
  children,
}) {
  useEffect(() => {
    if (isSuccess) {
      if (onLoadSuccess) {
        onLoadSuccess();
      }
    } else if (isFailure || isCancel) {
      if (onLoadFailure) {
        onLoadFailure();
      }
    } else if (onLoadForm) {
      onLoadForm();
    }
  }, [
    isSuccess,
    onLoadSuccess,
    isFailure,
    isCancel,
    onLoadFailure,
    onLoadForm,
  ]);

  if (isSuccess) {
    return renderSuccess();
  }

  if (isFailure || isCancel) {
    return renderFailure();
  }

  return children;
}
