import { Box, Checkbox, Grid, Label } from "theme-ui";
import React from "react";
import PropTypes from "prop-types";
import { get, noop } from "sites-common/utils/lodash";
import {
  DayPickerInput,
  FlexColumn,
  Radios
} from "../../index";
import { formatToIST } from "./utils";
import useDonationConf from "../../../DonationComponent/hooks/useDonationConf";

export const useOnlineSection = (args = {}) => {
  const {
    account_type = "",
    start_date = null,
    untilCancelled = true,
    end_date = null,
    onChange = noop,
  } = args;

  const ACCOUNT_TYPE_OPTIONS = React.useMemo(
    () => [
      { value: "Savings", label: "Savings" },
      { value: "Current", label: "Current/Checking" },
      { value: "CC", label: "CC" },
    ],
    []
  );

  const onChangeOnlineSection = React.useCallback(
    (update) => {
      onChange({
        account_type,
        start_date,
        untilCancelled,
        end_date,
        ...update,
      });
    },
    [account_type, start_date, untilCancelled, end_date, onChange]
  );

  const startDateInput = React.useMemo(
    () => ({
      value: start_date,
      inputProps: {
        readOnly: true,
      },
      formatDate: formatToIST,
      dayPickerProps: {
        selectedDays: start_date,
        onDayClick: (start_date1, { disabled }) =>
          !disabled &&
          onChangeOnlineSection({
            start_date: start_date1,
            end_date: null,
            untilCancelled: true,
          }),
        modifiers: {
          disabled: {
            before: new Date(),
          },
        },
        initialMonth: start_date || new Date(),
      },
    }),
    [start_date, onChangeOnlineSection]
  );

  const setEndDate = (startDate) =>
    new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() + 3));

  const endDateInput = React.useMemo(
    () => ({
      value: end_date,
      inputProps: {
        disabled: Boolean(untilCancelled || Boolean(!startDateInput.value)),
        readOnly: true,
      },
      formatDate: formatToIST,
      dayPickerProps: {
        selectedDays: end_date,
        onDayClick: (end_date1, { disabled }) =>
          !disabled && onChangeOnlineSection({ end_date: end_date1 }),
        initialMonth: end_date || setEndDate(startDateInput.value),
        modifiers: {
          disabled: {
            before: setEndDate(startDateInput.value) || new Date(),
          },
        },
      },
    }),
    [end_date, onChangeOnlineSection, startDateInput.value, untilCancelled]
  );

  const untilCancelledCheckboxRef = React.useRef();

  const untilCancelledCheckbox = React.useMemo(
    () => ({
      defaultChecked: untilCancelled,
      ref: untilCancelledCheckboxRef,
      disabled: Boolean(!startDateInput.value),
      onChange: ({ target: { checked: untilCancelled1 } }) =>
        onChangeOnlineSection({
          untilCancelled: untilCancelled1,
          end_date: !untilCancelled1 ? setEndDate(startDateInput.value) : "",
        }),
    }),
    [untilCancelled, onChangeOnlineSection, startDateInput.value]
  );

  const accountTypeRadios = React.useMemo(
    () => ({
      name: "account_type",
      value: ACCOUNT_TYPE_OPTIONS[0].value,
      options: ACCOUNT_TYPE_OPTIONS,
      onChange: (account_type1) =>
        onChangeOnlineSection({
          account_type: account_type1,
        }),
    }),
    [onChangeOnlineSection, ACCOUNT_TYPE_OPTIONS]
  );

  React.useEffect(() => {
    if (untilCancelledCheckboxRef.current) {
      untilCancelledCheckboxRef.current.checked = true;
    }
  }, [start_date]);

  return {
    startDateInput,
    endDateInput,
    untilCancelledCheckbox,
    untilCancelled,
    accountTypeRadios,
  };
};

const OnlineSectionLayout = ({
  untilCancelledCheckbox,
  startDateInput,
  endDateInput,
  untilCancelled,
  accountTypeRadios,
  emailId,
}) => (
  <FlexColumn gap={13} variant="boxes.bgBox" p="2">
    {/* FROM DATE */}
    <Grid columns={2}>
      <DayPickerInput
        {...startDateInput}
        label="Start Date"
        inputProps={{
          ...startDateInput.inputProps,
          variant: "border-bottom",
        }}
      />
    </Grid>

    {/* UNTIL CANCELLED CHECKBOX & TO DATE DAYPICKER */}
    <Grid columns={2}>
      {/* UNTIL CANCELLED CHECKBOX */}
      <Box>
        <Label>End Date</Label>
        <Label
          sx={{
            alignItems: "center",
            fontSize: "14px",
            bg: untilCancelledCheckbox.disabled && "muted",
          }}
        >
          <Checkbox {...untilCancelledCheckbox} />
          Until cancelled
        </Label>
      </Box>

      {/* TO DATE */}
      {!untilCancelled && (
        <Box
          sx={{
            ".DayPickerInput-OverlayWrapper": {
              right: "107px",
            },
          }}
        >
          <DayPickerInput
            {...endDateInput}
            inputProps={{
              ...endDateInput.inputProps,
              variant: "border-bottom",
            }}
            label="End Date"
          />
          <Label sx={{ fontSize: "12px" }}>(Min 2 Installments)</Label>
        </Box>
      )}
    </Grid>
    <Box
      sx={{
        fontSize: "13px",
        backgroundColor: "background",
        padding: "7px",
        margin: "0px !important",
      }}
    >
      You can cancel at any time by sending email to {emailId}
    </Box>
    {/* ACCOUNT TYPE SELECTION */}
    <Box>
      <Label>Account Type</Label>
      <Grid>
        <Box>
          <Radios {...accountTypeRadios} />
        </Box>
      </Grid>
    </Box>
  </FlexColumn>
);

OnlineSectionLayout.propTypes = {
  untilCancelled: PropTypes.bool,
  untilCancelledCheckbox: PropTypes.shape({ disabled: PropTypes.bool }),
  startDateInput: PropTypes.shape({ inputProps: PropTypes.shape({}) }),
  endDateInput: PropTypes.shape({ inputProps: PropTypes.shape({}) }),
  accountTypeRadios: PropTypes.shape({}),
  emailId: PropTypes.string.isRequired,
};

OnlineSectionLayout.defaultProps = {
  untilCancelled: false,
  untilCancelledCheckbox: { disabled: false },
  startDateInput: { inputProps: {} },
  endDateInput: { inputProps: {} },
  accountTypeRadios: {},
};

const OnlineSection = (props) => {
  const {
    account_type,
    start_date,
    untilCancelled,
    end_date,
    onChange,
    country,
  } = props;
  const { conf } = useDonationConf();

  const contactMail = get(conf.contactMail, country);
  const onlineSection = useOnlineSection({
    account_type,
    start_date,
    untilCancelled,
    end_date,
    onChange,
  });
  return <OnlineSectionLayout emailId={contactMail} {...onlineSection} />;
};

OnlineSection.propTypes = {
  account_type: PropTypes.string,
  start_date: PropTypes.instanceOf(Date),
  end_date: PropTypes.instanceOf(Date),
  untilCancelled: PropTypes.bool,
  onChange: PropTypes.func,
  country: PropTypes.string,
};

OnlineSection.defaultProps = {
  account_type: "savings",
  start_date: null,
  untilCancelled: true,
  end_date: null,
  onChange: noop,
  country: "in",
};

export default React.memo(OnlineSection);
