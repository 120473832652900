/** @jsx jsx */
import { jsx, Box, Checkbox, Flex, Badge, useThemeUI, Button } from "theme-ui";
import { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { noop } from "sites-common/utils/lodash";
import  ConfirmButton  from "gatsby-plugin-hfn-profile/components/ConfirmButton";
import useDonorProfiles from "../../hooks/useDonorProfiles";
import EditProfile from "./EditProfile";

export const RenderCard = ({
  country,
  me,
  iAmSelected,
  editMe,
  selectMe,
  deleteMe,
}) => {
  const { theme } = useThemeUI();
  const { identificationType, identificationNumber } = me;
  return (
    <div sx={{ p: 2, mb: 2, ...theme.boxes?.bgBox }}>
      <Flex>
        <Box onClick={selectMe} sx={{ cursor: "pointer" }}>
          {!!selectMe && <Checkbox checked={iAmSelected} onChange={selectMe} />}
        </Box>
        <Box sx={{ flex: "1 1 auto", wordBreak: "break-all" }}>
          <div>
            <b>{me.name}</b>
            <span
              sx={{
                ml: 2,
                fontSize: "0.75rem",
                fontStyle: "italic",
                color: "secondary",
              }}
            >
              {me.nickname}
            </span>
          </div>
          {iAmSelected && (
            <div>
              <div sx={{ fontSize: "0.8rem", color: "secondary" }}>
                <i>email:&nbsp;</i> {me.email}
                <br />
                <i>phone:&nbsp;</i> {me.phone}
              </div>
              {country === "in" && (
                <div sx={{ fontSize: "0.8rem", color: "secondary" }}>
                  <i> id:&nbsp; </i>
                  {identificationNumber ? (
                    `${identificationNumber} (${identificationType})`
                  ) : (
                    <Badge variant="danger">Not Provided</Badge>
                  )}
                </div>
              )}
            </div>
          )}
        </Box>

        {(editMe || deleteMe) && (
          <Box sx={{ textAlign: "right" }}>
            <Flex sx={{ gap: 2, px: 2 }}>
              <div sx={{ mt: "0" }}>
                <FontAwesomeIcon
                  title="Edit Donor Profile"
                  sx={theme.buttons?.icon}
                  onClick={editMe}
                  icon="pencil-alt"
                />
              </div>
              <ConfirmButton title={`Deleting ${me.name}`} onClick={deleteMe}>
                <FontAwesomeIcon
                  sx={theme.buttons?.icon}
                  title="Delete Donor Profile"
                  icon="trash"
                />
              </ConfirmButton>
            </Flex>
          </Box>
        )}
      </Flex>
    </div>
  );
};

RenderCard.propTypes = {
  country: PropTypes.string.isRequired,
  me: PropTypes.objectOf(PropTypes.any),
  iAmSelected: PropTypes.bool.isRequired,
  editMe: PropTypes.func,
  selectMe: PropTypes.func,
  deleteMe: PropTypes.func,
};

RenderCard.defaultProps = {
  me: {},
  editMe: noop,
  selectMe: noop,
  deleteMe: noop,
};

// Required for Adding New Profile only
// initProfile
//   -- signedInProfile -- only for adding new profile and only for the first time or on 'fill from profile' button
//

function ProfileSelector({
  country,
  donorInfo,
  initProfile,
  setDonorInfo,
  resetDonorInfo,
  AfterSelection,
  containerStyle,
  enableLastname,
  showOtherCitizen,
}) {
  const { relevantProfiles, deleteProfile } = useDonorProfiles(country);
  const [editMode, setEditMode] = useState(false);
  const newProfile = typeof editMode !== "string";
  const resetEditMode = useCallback(() => setEditMode(false), []);
  const mode1 = !editMode;
  const mode2 = !mode1 && newProfile;
  const mode3 = !mode1 && !mode2;

  return (
    <div>
      {mode1 && (
        <div>
          <div sx={{ color: "secondary" }}>Select Donor Profile</div>
          {Object.values(relevantProfiles).map((p) => (
            <RenderCard
              key={p.uniqid}
              me={p}
              deleteMe={() => {
                deleteProfile(p.uniqid);
                if (donorInfo.uniqid === p.uniqid) {
                  resetDonorInfo();
                }
              }}
              editMe={() => {
                setEditMode(p.uniqid);
                resetDonorInfo();
              }}
              selectMe={() => setDonorInfo(p)}
              iAmSelected={donorInfo.uniqid === p.uniqid}
              country={country}
            />
          ))}

          <Button variant="variant6" onClick={() => setEditMode(true)}>
            {" "}
            Use a new donor profile{" "}
          </Button>

          <AfterSelection />
        </div>
      )}
      {mode2 && (
        <div>
          <div sx={{ color: "secondary", mb: 2 }}>Enter Donor Information</div>
          <EditProfile
            country={country}
            value={initProfile}
            setValue={(v) => {
              resetEditMode();
              setDonorInfo(v);
            }}
            discardChanges={resetEditMode}
            containerStyle={containerStyle}
            enableLastname={enableLastname}
            showOtherCitizen={showOtherCitizen}
          />
        </div>
      )}
      {mode3 && (
        <div>
          <div sx={{ color: "secondary", mb: 2 }}>Update Donor Information</div>

          <EditProfile
            country={country}
            value={relevantProfiles[editMode]}
            setValue={resetEditMode}
            discardChanges={resetEditMode}
            containerStyle={containerStyle}
            enableLastname={enableLastname}
            showOtherCitizen={showOtherCitizen}
          />
        </div>
      )}
    </div>
  );
}

ProfileSelector.propTypes = {
  country: PropTypes.string,
  initProfile: PropTypes.shape({}),
  setDonorInfo: PropTypes.func.isRequired,
  resetDonorInfo: PropTypes.func.isRequired,
  AfterSelection: PropTypes.func.isRequired,
  containerStyle: PropTypes.shape({}),
  donorInfo: PropTypes.objectOf(PropTypes.any),
  enableLastname: PropTypes.bool,
  showOtherCitizen: PropTypes.bool,
};

ProfileSelector.defaultProps = {
  country: "in",
  containerStyle: {},
  enableLastname: false,
  donorInfo: {},
  initProfile: {},
  showOtherCitizen: true,
};

export default ProfileSelector;
