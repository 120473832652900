/** @jsx jsx */
import React, { useCallback, useReducer, useState, useEffect } from "react";
import { Flex, jsx, useThemeUI, Grid, Box, Button } from "theme-ui";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import { DynamicFieldStyle1 as DynamicField } from "sites-common/components/DynamicField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignInButton from "gatsby-plugin-hfn-profile/auth/SignInButton";
import useAuth from "gatsby-plugin-hfn-profile/auth/useAuth";
import useDonorProfiles from "../../hooks/useDonorProfiles";
import IndiaFormPartTax from "./ProfileTaxFields/India";
import validateProfile from "./validateProfile";
import ErrorsOrWarnings from "../ErrorsOrWarnings";

function transformFromSrcm(srcmProfile, enableLastname, profile) {
  if (srcmProfile) {
    const {
      name,
      email,
      phone,
      streetAddress: address,
      postalCode,
      state,
      city,
      city_id,
      country,
      firstName,
      lastName,
    } = srcmProfile;
    return {
      name: enableLastname ? firstName : name,
      lastName,
      email,
      phone: phone ? phone.replace(/[^0-9+]/g, "") : "+91",
      address,
      citizenship: profile.citizenship,
      citizenship_id: profile.citizenship_id,
      indian: profile.country && profile.country === "India",
      city,
      city_id,
      postalCode:
        country && country === "India"
          ? postalCode.replace(/[^0-9]/g, "")
          : postalCode,
      state,
      country: profile.country,
    };
  }
  return {};
}

const SET = "set-val";
function reducer1(state, action) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        [action.k]: action.v,
        ...(action.extras ? action.extras : {}),
      };
    default:
      throw new Error();
  }
}

function EditProfile({
  autoFocusName = "name",
  country = "in",
  ButtonPay,
  value,
  setValue,
  discardChanges,
  containerStyle = {},
  enableLastname,
  showOtherCitizen,
  enableSignInOption,
}) {
  const [doProfile, dispatch1] = useReducer(reducer1, value);
  const [saveIt] = useState(true);
  const { saveProfile } = useDonorProfiles(country);
  const [ew, setErrors] = useState("");
  const { srcmProfile } = useAuth();
  const { theme } = useThemeUI();
  const [inited, setInited] = useState(false);

  const doSetProfile = (v, k, extras) => {
    setErrors("");
    const extras1 = { ...extras };
    if (k === "email") {
      extras1["warning-email-domain"] = false;
    }
    dispatch1({ type: SET, v, k, extras: extras1 });
  };

  useEffect(() => {
    // Checking email on this for mobile web view
    if (!!srcmProfile && !doProfile.name && !doProfile.email) {
      const p = transformFromSrcm(srcmProfile, enableLastname, doProfile);
      doSetProfile(p.name, "name", p);
      setInited(true);
    }
  }, [srcmProfile, inited, doProfile]);

  useEffect(() => {
    if (!("uniqid" in value)) {
      doSetProfile(`${uuidv4().substring(0, 6)}`, "uniqid");
    }
  }, [value]);

  const handleCityChange = useCallback((v, k, extras) => {
    doSetProfile(v, k, extras);
    doSetProfile("", "postalCode");
  }, []);

  const goSave = () => {
    const profileData = doProfile;
    if (!doProfile.nickname) {
      doSetProfile("myself", "nickname");
      Object.assign(profileData, {
        nickname: "myself",
      });
    }

    const ew1 = validateProfile(
      country,
      profileData,
      null,
      null,
      true,
      null,
      enableLastname
    );
    setErrors(ew1);

    if (ew1.errors.length > 0) {
      return;
    }

    if (
      ew1.warnings
        .map((x) => (typeof x === "string" ? x : x.code))
        .filter(
          (x) =>
            !(x in doProfile && doProfile[x]) &&
            x !== "warning-in-pan" &&
            x !== "warning-in-identity"
        ).length > 0
    ) {
      return;
    }

    // XXX add code for validations
    const validated = true;

    if (validated) {
      if (saveIt) {
        saveProfile(doProfile);
      }
      setValue(doProfile);
    }
  };

  const overrideEmailWarning = useCallback(() => {
    doSetProfile(true, "warning-email-domain");
    // goSave();
  }, []);

  const errors = ew && ew.errors.length > 0;
  const ew_warnings = ew
    ? ew.warnings
        .map((x) => (typeof x === "string" ? x : x.code))
        .filter(
          (x) =>
            !(x in doProfile && doProfile[x]) &&
            x !== "warning-in-pan" &&
            x !== "warning-in-identity"
        )
    : [];
  const warnings = ew && ew_warnings.length > 0;

  return (
    <div>
      {!srcmProfile && (
        <div sx={{ float: "right" }}>
          {enableSignInOption && (
            <SignInButton
              btn={<Button variant="variant6">Sign In to auto-fill</Button>}
            />
          )}
        </div>
      )}
      <DynamicField
        containerStyle={containerStyle}
        name="name"
        label="Name"
        icon="user"
        value={doProfile.name}
        onChange={doSetProfile}
      />
      {enableLastname && (
        <DynamicField
          containerStyle={containerStyle}
          name="lastName"
          label="Last Name"
          icon="user"
          value={doProfile.lastName}
          onChange={doSetProfile}
        />
      )}
      <DynamicField
        containerStyle={containerStyle}
        name="email"
        type="email"
        label="Email Id"
        icon="envelope"
        value={doProfile.email}
        onChange={doSetProfile}
      />
      <DynamicField
        containerStyle={containerStyle}
        name="phone"
        type="phone"
        label="Phone"
        icon="phone"
        enableSearch
        countryCodeEditable={false}
        country={country}
        value={doProfile.phone}
        onChange={doSetProfile}
      />
      <DynamicField
        zq={country}
        containerStyle={containerStyle}
        name="city_id"
        label="City"
        type="city_id_nostrict_allownew"
        options={{ city: "name", state: "state", country: "country" }}
        icon="map-marked-alt"
        value={doProfile.city_id}
        onChange={handleCityChange}
      />
      {doProfile.city_id && typeof doProfile.city_id !== "number" && (
        <React.Fragment>
          <DynamicField
            containerStyle={containerStyle}
            name="country_id"
            label="Country"
            type="country_id"
            options={{ country: "name" }}
            icon="globe"
            value={doProfile.country_id}
            onChange={doSetProfile}
          />
        </React.Fragment>
      )}
      <DynamicField
        containerStyle={containerStyle}
        name="address"
        label="Street Address"
        icon="home"
        value={doProfile.address}
        onChange={doSetProfile}
      />
      <DynamicField
        containerStyle={{ ...containerStyle, textTransform: "capitalize" }}
        type={doProfile.country === "India" ? "number" : ""}
        name="postalCode"
        icon="star-of-david"
        label={`${doProfile.country === "India" ? "Pin" : "Zip"}code`}
        value={doProfile.postalCode}
        onChange={doSetProfile}
      />
      {country === "in" && (
        <div sx={{ ...theme.text?.description, my: 2 }}>
          {" "}
          Please provide the address in full, without which the organization
          needs to pay 30% tax on these donations{" "}
        </div>
      )}
      {country === "in" ? (
        <IndiaFormPartTax
          autoFocusName={autoFocusName}
          profile={doProfile}
          setProfile={doSetProfile}
          containerStyle={containerStyle}
          showOtherCitizen={showOtherCitizen}
        />
      ) : (
        <DynamicField
          disabled={!showOtherCitizen}
          containerStyle={{
            ...containerStyle,
            input: !showOtherCitizen
              ? { opacity: "0.8", cursor: "no-drop" }
              : "",
          }}
          label="Citizenship"
          name="citizenship_id"
          type="country_id"
          options={{ citizenship: "name" }}
          value={doProfile.citizenship_id}
          onChange={(v, k, e) => {
            const val = v || 0;
            doSetProfile(val, k, {
              ...e,
            });
          }}
        />
      )}
      <div sx={{ mt: 4, width: "200px" }}>
        {/* <Label sx={{ my: 2 }}> Add to Favorites </Label> */}
        {/* <ToggleButton inactiveLabel={"No"} activeLabel={"Yes"} value={saveIt} onToggle={(value) => { setSaveIt(!value); }} /> */}
        <DynamicField
          containerStyle={containerStyle}
          name="nickname"
          label="Nickname"
          value={doProfile.nickname}
          onChange={doSetProfile}
        />
        <div sx={{ ...theme.text?.description, mx: 1, mb: 2 }}>
          {" "}
          Enter a nickname to save this profile{" "}
        </div>
      </div>
      <br />

      {(errors || warnings) && (
        <div sx={theme.messages?.variantEW}>
          <div sx={{ color: "errortext" }}>
            <Grid gap={1} columns={["1fr 7fr"]}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon
                  icon="exclamation-circle"
                  sx={{ fontSize: "25px" }}
                />
              </Box>
              <Box>
                <Grid gap={0}>
                  <Box>
                    <p sx={{ mb: 0 }}>
                      <b>Form Errors.</b>
                    </p>
                  </Box>
                  <Box>Please fix to continue!</Box>
                </Grid>
              </Box>
            </Grid>
          </div>
          {errors && <ErrorsOrWarnings errors={ew.errors} />}
          {warnings && (
            <div>
              <ErrorsOrWarnings warnings={ew.warnings} />
              {ew_warnings.includes("warning-email-domain") && (
                <div>
                  Please fix it or click the following button to ignore this
                  warning
                  <Button variant="variant1" onClick={overrideEmailWarning}>
                    {" "}
                    {doProfile.email} is correct{" "}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <br />
      {!warnings &&
        (ButtonPay ? (
          <ButtonPay onClick={goSave} />
        ) : (
          <Flex sx={{ gap: 2 }}>
            <Button variant="variant1" onClick={goSave}>
              {" "}
              Save{" "}
            </Button>
            {discardChanges && (
              <Button variant="variant3" onClick={discardChanges}>
                Discard
              </Button>
            )}
          </Flex>
        ))}

      <br />
      <br />
    </div>
  );
}

EditProfile.defaultProps = {
  autoFocusName: "name",
  containerStyle: {},
  enableLastname: false,
  showOtherCitizen: true,
};

EditProfile.propTypes = {
  autoFocusName: PropTypes.string,
  country: PropTypes.string.isRequired,
  ButtonPay: PropTypes.func.isRequired,
  value: PropTypes.shape({}).isRequired,
  setValue: PropTypes.func.isRequired,
  discardChanges: PropTypes.func.isRequired,
  containerStyle: PropTypes.shape({}),
  enableLastname: PropTypes.bool,
  showOtherCitizen: PropTypes.bool,
  enableSignInOption: PropTypes.bool.isRequired,
};

export default EditProfile;
