import { useDispatch } from "react-redux";
import { showAlert, dismissAlert } from "./state/actions";

export const useAlert = () => {
  const dispatch = useDispatch();

  return {
    showAlert: ({
      message,
      confirm_text = "",
      confirm_callback = "",
      cancel_text = "OK",
      title = "",
      is_blocking = false,
    }) =>
      dispatch(
        showAlert({
          title,
          message,
          confirm_text,
          confirm_callback,
          cancel_text,
          is_blocking,
        })
      ),
    dismissAlert: () => dispatch(dismissAlert()),
  };
};
