function postUrl(path, params, method = "post", target = "") {
  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.

  const form = document.createElement("form");
  form.method = method;
  form.action = path;
  form.target = target;

  Object.keys(params).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  });
  document.body.appendChild(form);
  form.submit();
}

export default postUrl;
