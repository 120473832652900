import PropTypes from "prop-types";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { mySrcmConfig } = getMandatoryEnv(["mySrcmConfig"]);
const u = (p) => p.toUpperCase().trim();

const currencyApi = (base) => {
  return `${mySrcmConfig.profileCacheServer}/rates/${u(base)}.json`;
};

currencyApi.propTypes = {
  // mySrcmConfig: PropTypes.shape({
  //   profileCacheServer: PropTypes.string.isRequired
  // }),
  base: PropTypes.string,
};

export default currencyApi;
