/** @jsx jsx */
import { jsx, Button, Flex, Input, Text } from "theme-ui";
import { useState } from "react";

// Different Layouts
// variant 1 -- buttons
// variant 2 -- amount_and_button
// variant 3 -- buttons_with_other

const AmountSelectorsWithAmount = ({
  layoutProps = {},
  updateAmount,
  showDonations,
  rate = 1,
}) => {
  const {
    btnStyle = {},
    btnText = "Donate Now",
    inputStyle = {},
    initialInput = "",
  } = layoutProps;
  const [amount, setAmount] = useState(initialInput || "");
  return (
    <Flex>
      <Input
        sx={inputStyle}
        name="amount"
        value={`${amount}`}
        onChange={(e) => {
          const x = e.target.value.replace(/^\D+/g, "");
          setAmount(x ? parseInt(x, 10) : "");
        }}
      />
      <Button
        sx={{ padding: "10px", ...btnStyle }}
        onClick={() => {
          updateAmount(amount * rate);
          showDonations();
        }}
      >
        {btnText}
      </Button>
    </Flex>
  );
};

const AmountSelectorsForObjects = ({
  layoutProps = {},
  updateAmount,
  showDonations,
}) => {
  const {
    btnStyle = {},
    buttons = [],
    showOther = false,
    rate = 1,
    item = "Trees",
    inputStyle = {},
    layoutStyle = { display: "flex", padding: "8px" },
  } = layoutProps;
  return (
    <div>
      <div style={layoutStyle}>
        {buttons.map((b, bidx) => {
          const { text, qty = 0 } = b;
          return (
            <Button
              key={bidx}
              sx={btnStyle}
              onClick={() => {
                updateAmount(qty * rate);
                showDonations();
              }}
            >
              {text || `${qty} ${item}`}
            </Button>
          );
        })}
      </div>

      {showOther && (
        <div sx={{ mt: buttons.length > 0 ? 3 : 0 }}>
          <AmountSelectorsWithAmount
            layoutProps={{ btnStyle, btnText: showOther, inputStyle }}
            updateAmount={updateAmount}
            showDonations={showDonations}
            rate={rate}
          />
        </div>
      )}
    </div>
  );
};

export const AmountSelectors = (props) => {
  const { layout, showDonations } = props;
  switch (layout) {
    case "amount_and_button":
      return <AmountSelectorsWithAmount {...props} />;
    case "buttons":
      return <AmountSelectorsForObjects {...props} />;

    default:
      return (
        <Button
          sx={{ color: "white", bg: "red", "&:hover": { bg: "darkred" } }}
          onClick={showDonations}
        >
          {" "}
          Donate Now{" "}
        </Button>
      );
  }
};
