/** @jsx jsx */
import {
  jsx,
  Box,
  Button,
  Grid,
  Spinner,
  Container,
  useThemeUI,
} from "theme-ui";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import HtmlDiv from "sites-common/components/HtmlDiv";
import submitDonationRequest from "../service-wrappers/donation-service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePersist } from "gatsby-plugin-hfn-profile/globals";
import Tos from "gatsby-plugin-hfn-profile/components/Tos";
import CurrencySymbol from "../../ui/CurrencySymbol";
import validateProfile from "./DonorProfile/validateProfile";
import AmountSelector from "./Amount/AmountSelector";
import ProfileSelector, { RenderCard } from "./DonorProfile/ProfileSelector";

import ErrorsOrWarnings from "./ErrorsOrWarnings";
import EditProfile from "./DonorProfile/EditProfile";
import useDonorProfiles from "../hooks/useDonorProfiles";
import { checkRestrictions } from "./restrictions";
import OnlyOnlineRecurringDonation, {
  useOnlyOnlineRecurringDonation,
} from "../../ui/Feature/RecurringDonations/OnlyOnline";
import useDonationConf from "../hooks/useDonationConf";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const { donationServiceConfig } = getMandatoryEnv(["donationServiceConfig"]);

const MONTHLY_RECURRING_DONATION_DEFAULT_STATE = {
  isMonthlyRecurringDonation: true,
  donation_type: "ONLINE",
  onlineSectionDetails: {
    account_type: "Savings",
    end_date: null,
    start_date: new Date(),
    untilCancelled: true,
  },
  offlineSectionDetails: {
    payment_option: "STANDING_INSTRUCTION",
    standingInstructionDetails: {
      payment_mode: "INTERNET_BANKING", // default value
      netBankingDetails: {
        donor_bank: "",
        standing_date: null,
        donor_ifsc_code: "",
      },
      walletDetails: {
        wallet_mobile: "",
        standing_date: null,
      },
    },
  },
};

const doValidateSubmit = ({
  country,
  systemInfo,
  donorInfo,
  amount,
  currency,
  onErrors,
  onWarnings,
  setLoading,
  onSuccess,
  monthlyRecurringDonationState,
  overrideWarnings = [],
}) => {
  const { errors, warnings } = validateProfile(
    country,
    donorInfo,
    amount,
    currency,
    false,
    monthlyRecurringDonationState
  );
  if (errors.length) {
    // console.error(errors);
    onErrors(errors);
    onWarnings(warnings);
    return;
  }

  if (warnings) {
    const pendingWarnings = warnings.filter(
      (w) => !overrideWarnings.includes(w)
    );
    if (pendingWarnings.length) {
      // console.warn(warnings);
      onWarnings(pendingWarnings);
      return;
    }
  }

  const formData = {
    donationServiceConfig,
    ...systemInfo,
    ...donorInfo,
    currency,
    amount,
  };
  submitDonationRequest(
    formData,
    setLoading,
    onSuccess,
    (s) => onErrors([s]),
    monthlyRecurringDonationState
  );
};

const DonationWidget = ({
  formDonationId,
  initialAmount,
  initialCurrency,
  projectId,
  packageDescription,
  country,
  initialProfile,
  enableRecurring,
  enableLastname,
  paymentSuccessUrl,
  paymentFailureUrl,
  showOtherCitizen,
  enableSignInOption,
}) => {
  const { theme } = useThemeUI();
  const [to] = useState(initialCurrency);
  const [toAmount, setToAmount] = useState(initialAmount);

  const [monthlyRecurringDonationState, setMonthtlyRecurringDonationState] =
    useState(enableRecurring ? MONTHLY_RECURRING_DONATION_DEFAULT_STATE : "");
  const monthlyRecurringDonationSection = useOnlyOnlineRecurringDonation({
    ...monthlyRecurringDonationState,
    onChange: setMonthtlyRecurringDonationState,
  });

  const [donorInfo, setDonorInfo] = useState({});

  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [loading, setLoading] = useState(false);

  const [editDonorField, setEditDonorField] = useState(false);
  const { relevantProfiles, numProfiles } = useDonorProfiles(country);

  const { conf } = useDonationConf();

  const [trk, setTrk] = usePersist("donation-trk", {});
  const onSuccess = useCallback(
    (d) => {
      setTrk({
        ...trk,
        [d.donation.payment.trackingId]: d.donation.userEmailAddress,
      });
    },
    [trk, setTrk]
  );

  const containerStyle = {
    mb: 1,
    borderBottom: `thin ${theme.colors?.primary} solid`,
  };

  useEffect(() => {
    // Fix for scroll down while focusing on form fields in mobile (IOS and Android)
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const systemInfo = useMemo(
    () => ({
      donation_id: formDonationId,
      projects: [projectId],
      packageInfo: packageDescription,
      paymentSuccessUrl,
      paymentFailureUrl,
    }),
    [formDonationId, projectId, packageDescription]
  );

  const resetDonorInfo = useCallback(() => {
    setDonorInfo(initialProfile);
    setErrors([]);
    setWarnings([]);
  }, [initialProfile]);

  const setDonorInfoAndTriggerPay = useCallback(
    (p) => {
      setDonorInfo(p);
      setEditDonorField(false);

      if (p) {
        doValidateSubmit({
          donationServiceConfig,
          country,
          systemInfo,
          donorInfo: p,
          amount: toAmount,
          currency: to,
          onErrors: setErrors,
          onWarnings: setWarnings,
          setLoading,
          onSuccess,
          monthlyRecurringDonationState,
          overrideWarnings: [],
        });
      }
    },
    [toAmount, to, country, onSuccess, systemInfo]
  );

  const validateAndSubmit = useCallback(() => {
    doValidateSubmit({
      donationServiceConfig,
      country,
      systemInfo,
      donorInfo,
      amount: toAmount,
      currency: to,
      onErrors: setErrors,
      onWarnings: setWarnings,
      setLoading,
      onSuccess,
      monthlyRecurringDonationState,
      overrideWarnings: [],
    });
  }, [
    donorInfo,
    toAmount,
    to,
    setErrors,
    setWarnings,
    setLoading,
    onSuccess,
    monthlyRecurringDonationState,
    country,

    systemInfo,
  ]);

  const validateAndSubmitOW = useCallback(() => {
    doValidateSubmit({
      donationServiceConfig,
      country,
      systemInfo,
      donorInfo,
      amount: toAmount,
      currency: to,
      onErrors: setErrors,
      onWarnings: setWarnings,
      setLoading,
      onSuccess,
      monthlyRecurringDonationState,
      overrideWarnings: ["warning-in-pan", "warning-in-identity"],
    });
  }, [
    donorInfo,
    toAmount,
    to,
    setErrors,
    setWarnings,
    setLoading,
    onSuccess,
    country,

    systemInfo,
  ]);

  // profileModes = ["restrictions", "selection-box", "editor-box", "warnings-box"];

  const restrictions = checkRestrictions({
    conf,
    country,
    amount: toAmount,
    donorInfo,
    donationId: formDonationId,
  });

  let show = "";
  if (restrictions.length > 0) {
    show = "restrictions";
  } else if (numProfiles > 0 && !editDonorField && warnings.length === 0) {
    show = "selection-box";
  } else if (!!editDonorField || (numProfiles === 0 && warnings.length === 0)) {
    show = "editor-box";
  } else {
    show = "warnings-box";
  }

  const openInfoEditor = useCallback(() => {
    setErrors([]);
    setWarnings([]);
    setEditDonorField("pan");
  }, []);

  const ButtonPayText = useCallback((extraText) =>
    country === "in" && enableRecurring ? (
      "Proceed"
    ) : (
      <React.Fragment>
        Proceed to Pay <CurrencySymbol to={to} /> {toAmount} {extraText}
      </React.Fragment>
    )
  );

  const ButtonPay = useCallback(
    ({ extraText, ...restProps }) => (
      <div>
        <Button sx={{ ...theme.buttons?.variant1 }} {...restProps}>
          {" "}
          {loading && <Spinner size={16} sx={{ color: "text" }} />}
          {ButtonPayText(extraText)}
        </Button>
        {enableRecurring && country === "in" && (
          <Box
            sx={{
              color: "text",
              textAlign: "center",
              margin: "5px 0 10px 0",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Note: By Proceeding you will be setting up the ECS subscription with
            your Bank
          </Box>
        )}
        <Tos country={country} sx={{ color: "text", fontWeight: "600" }} />
      </div>
    ),
    [to, toAmount, loading, country, theme.buttons?.variant1]
  );

  const AfterSelection = useCallback(
    () => (
      <div sx={{ mt: 5 }}>
        {errors.length > 0 && (
          <div sx={theme.messages?.variantEW}>
            <div sx={{ color: "errortext" }}>
              <Grid gap={1} columns={["1fr 7fr"]}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon="exclamation-circle"
                    sx={{ fontSize: "25px" }}
                  />
                </Box>
                <Box>
                  <Grid gap={0}>
                    <Box>
                      <h4 sx={{ mb: 0 }}>
                        <b>Form Errors.</b>
                      </h4>
                    </Box>
                    <Box>
                      <h5>Please fix to continue!</h5>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </div>
            <ErrorsOrWarnings errors={errors} warnings={warnings} />
          </div>
        )}
        {donorInfo.name && (
          <ButtonPay
            disabled={loading || errors.length > 0}
            onClick={validateAndSubmit}
          />
        )}
      </div>
    ),
    [
      errors,
      warnings,
      loading,
      validateAndSubmit,
      donorInfo,
      theme.messages?.variantEW,
    ]
  );

  const notInited = !("uniqid" in donorInfo) && numProfiles > 0;

  useEffect(() => {
    // on the first entry, if no donorInfo is selected, set to the first one
    let inited = false;
    if (notInited) {
      Object.values(relevantProfiles).forEach((v) => {
        if (!inited) {
          const restrictions1 = checkRestrictions({
            conf,
            country,
            amount: 0,
            donorInfo: v,
            donationId: formDonationId,
          });
          if (restrictions1.length === 0) {
            setDonorInfo(v);
            inited = true;
          }
        }
      });
    }
  }, [
    conf,
    country,
    notInited,
    formDonationId,
    setDonorInfo,
    relevantProfiles,
  ]);

  return (
    <Container sx={{ maxWidth: "320px", pb: 3, margin: "0 auto" }}>
      <div sx={{ mb: 5 }}>
        <AmountSelector
          country={country}
          currency={initialCurrency}
          initialAmount={initialAmount}
          initialCurrency={initialCurrency}
          setAmount={(x) => {
            setToAmount(x);
            setErrors([]);
            setWarnings([]);
          }}
          autoFocusName={editDonorField || "amount"}
        />
        {enableRecurring && (
          <OnlyOnlineRecurringDonation
            country={country}
            {...monthlyRecurringDonationSection}
          />
        )}
      </div>

      {show === "restrictions" && (
        <div>
          <RenderCard
            country={country}
            me={donorInfo}
            iAmSelected
            selectMe={resetDonorInfo}
          />
          <div
            sx={{
              ...theme.messages?.variantEW,
              fontFamily: `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"`,
            }}
          >
            {restrictions.map((msg, key) => {
              const x = `y${key}x`;
              return <HtmlDiv key={x} htmlString={msg} />;
            })}
          </div>
          <Button variant="variant3" onClick={resetDonorInfo}>
            Back
          </Button>
        </div>
      )}

      {show === "selection-box" && (
        <ProfileSelector
          containerStyle={containerStyle}
          country={country}
          initProfile={initialProfile}
          donorInfo={donorInfo}
          setDonorInfo={setDonorInfo}
          resetDonorInfo={resetDonorInfo}
          AfterSelection={AfterSelection}
          enableLastname={enableLastname}
          showOtherCitizen={showOtherCitizen}
        />
      )}

      {show === "editor-box" && (
        <div>
          <div sx={{ color: "secondary", mb: 1 }}>Donor Information</div>
          <EditProfile
            enableLastname={enableRecurring && enableLastname}
            country={country}
            ButtonPay={ButtonPay}
            value={editDonorField ? donorInfo : initialProfile}
            setValue={setDonorInfoAndTriggerPay}
            containerStyle={containerStyle}
            autoFocusName={editDonorField || "name"}
            showOtherCitizen={showOtherCitizen}
            enableSignInOption={enableSignInOption}
          />
        </div>
      )}

      {show === "warnings-box" && (
        <div>
          <RenderCard
            country={country}
            me={donorInfo}
            iAmSelected
            selectMe={resetDonorInfo}
          />
          {errors.length > 0 && <AfterSelection />}
          {errors.length === 0 && warnings.length > 0 && (
            <div>
              <div sx={theme.messages?.variantEW}>
                <Grid>
                  <Box
                    sx={{
                      marginLeft: "25px",
                    }}
                  >
                    <Grid gap={1} columns={["1fr 7fr"]}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon="exclamation-circle"
                          sx={{ fontSize: "30px" }}
                        />
                      </Box>
                      <Box>
                        <h5 sx={{ marginTop: "5px" }}>
                          {" "}
                          <b> Please Note: </b>
                        </h5>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>

                <ErrorsOrWarnings warnings={warnings} />
                {(warnings.includes("warning-in-pan") ||
                  warnings.includes("warning-in-identity")) && (
                  <div
                    sx={{
                      ...theme.messages?.variantW,
                    }}
                  >
                    {warnings.includes("warning-in-pan") && (
                      <div
                        sx={{
                          ...theme.messages?.variantW,
                        }}
                      >
                        <span>The organization would receive</span>{" "}
                        <span>
                          <b>
                            {" "}
                            Only {to} {Math.round(toAmount * 0.7)}{" "}
                          </b>{" "}
                          after tax{" "}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {(warnings.includes("warning-in-pan") ||
                warnings.includes("warning-in-identity")) && (
                <div>
                  <Button
                    sx={{ ...theme.buttons?.variant3, fontSize: "0.8em" }}
                    onClick={openInfoEditor}
                  >
                    {" "}
                    Enter Pancard &amp; claim Income Tax Benefit{" "}
                  </Button>
                  <br />
                  <ButtonPay
                    sx={{ fontSize: "0.85em" }}
                    disabled={loading || errors.length > 0}
                    onClick={validateAndSubmitOW}
                    extraText={<span> without Pancard </span>}
                  />
                  <br />
                </div>
              )}
            </div>
          )}
          {errors.length === 0 && warnings.length === 0 && (
            <ButtonPay disabled={loading} onClick={validateAndSubmit} />
          )}
        </div>
      )}
    </Container>
  );
};

DonationWidget.propTypes = {
  formDonationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  initialAmount: PropTypes.number,
  initialCurrency: PropTypes.string,
  projectId: PropTypes.string,
  packageDescription: PropTypes.string,
  country: PropTypes.string,
  initialProfile: PropTypes.shape({}),
  enableRecurring: PropTypes.bool,
  enableLastname: PropTypes.bool,
  paymentSuccessUrl: PropTypes.string.isRequired,
  paymentFailureUrl: PropTypes.string.isRequired,
  showOtherCitizen: PropTypes.bool,
  enableSignInOption: PropTypes.bool,
};

DonationWidget.defaultProps = {
  initialAmount: 0,
  initialCurrency: "INR",
  projectId: "",
  packageDescription: "",
  country: "in",
  initialProfile: {},
  enableRecurring: false,
  enableLastname: false,
  showOtherCitizen: true,
  enableSignInOption: true,
};
export default DonationWidget;
