const format = (dt) =>
  dt ? [dt.getFullYear(), dt.getMonth() + 1, dt.getDate()].join("-") : "";

export const formatDateYYYYMMDD = (d) =>
  d
    ? [
        d.getFullYear(),
        (d.getMonth() + 1).toString().padStart(2, "0"),
        d.getDate().toString().padStart(2, "0"),
      ].join("-")
    : "";

export const getRecurringDonationPayloadDigio = ({
  monthlyRecurringDonationState,
  formData,
}) => {
  const {
    name: firstName,
    city,
    state,
    country,
    address,
    phone: phoneNumber,
    email: emailAddress,
    postalCode,
    citizenship,
    amount,
    identificationNumber,
    identificationType,
    memberId,
    currency,
    donation_id,
    paymentSuccessUrl: redirectUrl,
  } = formData;
  const payload = {
    amount,
    firstName,
    emailAddress,
    phoneNumber,
    city,
    state,
    country,
    postalCode,
    address,
    citizenship,
    pancard: identificationType === "Pancard" ? identificationNumber : "",
    identificationType,
    identificationNumber:
      identificationType !== "Pancard" ? identificationNumber : "",
    memberId,
    currency,
  };

  const { donation_type, onlineSectionDetails, offlineSectionDetails } =
    monthlyRecurringDonationState;
  Object.assign(payload, {
    donationType: donation_type,
  });
  switch (donation_type) {
    case "ONLINE":
      {
        const { account_type, start_date, end_date } = onlineSectionDetails;
        Object.assign(payload, {
          accountType: account_type,
          period: "Monthly",
          startDate: formatDateYYYYMMDD(start_date),
          endDate: formatDateYYYYMMDD(end_date),
        });
      }
      break;
    case "OFFLINE":
      {
        const { payment_option, standingInstructionDetails } =
          offlineSectionDetails;
        Object.assign(payload, {
          payment_option,
        });
        switch (payment_option) {
          case "STANDING_INSTRUCTION":
            {
              const { payment_mode, netBankingDetails, walletDetails } =
                standingInstructionDetails;
              Object.assign(payload, {
                payment_mode,
                period: "Monthly",
              });
              switch (payment_mode) {
                case "INTERNET_BANKING":
                  {
                    const { donor_bank, donor_ifsc_code, standing_date } =
                      netBankingDetails;
                    Object.assign(payload, {
                      donor_bank,
                      donor_ifsc_code,
                      standing_date: format(standing_date),
                    });
                  }
                  break;
                case "WALLET":
                  {
                    const { wallet_mobile, standing_date } = walletDetails;
                    Object.assign(payload, {
                      wallet_mobile,
                      standing_date: format(standing_date),
                    });
                  }
                  break;
                default:
                  break;
              }
            }
            break;

          case "ECS":
            break;
          default:
            break;
        }
      }
      break;
    default:
      break;
  }
  return {
    customerDetail: payload,
    otherDetail: { donationItemId: donation_id, redirectUrl },
  };
};

export const getRecurringPayloadAuthorizeDotNet = ({
  monthlyRecurringDonationState,
  formData,
}) => {
  const {
    name: firstName,
    lastName,
    city,
    state,
    country,
    address,
    phone: phoneNumber,
    email: emailAddress,
    postalCode,
    citizenship,
    currency,
    amount,
    donation_id,
    city_id: cityId,
    paymentSuccessUrl: redirectUrl,
  } = formData;

  const { onlineSectionDetails: recurringData } = monthlyRecurringDonationState;

  const payload = {
    customerDetail: {
      firstName,
      lastName,
      city,
      state,
      country,
      address,
      phoneNumber,
      emailAddress,
      postalCode,
      citizenship,
      currency,
      amount,
      period: "Monthly",
      startDate: format(recurringData.start_date),
      endDate: format(recurringData.end_date),
      accountType: recurringData.account_type,
      cityId,
      donationType: "ONLINE",
    },
    otherDetail: {
      donationItemId: donation_id,
      redirectUrl,
    },
  };

  return payload;
};
