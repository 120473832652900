import { Box, Label, Radio } from "theme-ui";
import PropTypes from "prop-types";
import React from "react";
import { noop } from "sites-common/utils/lodash";
import {
  InputWithIconAndLabel,
  DayPickerInput,
  FlexColumn,
} from "../../index";
import { formatToIST } from "./utils";

const useWalletSection = (args = {}) => {
  const { onChange = noop, standing_date = null, wallet_mobile = "" } = args;

  const onChangeWalletSection = React.useCallback(
    (update) => {
      onChange({
        standing_date,
        wallet_mobile,
        ...update,
      });
    },
    [standing_date, wallet_mobile, onChange]
  );
  const dayPickerInput = React.useMemo(
    () => ({
      value: standing_date,
      formatDate: formatToIST,
      inputProps: {
        readOnly: true,
      },
      dayPickerProps: {
        onDayClick: (standingDate) =>
          onChangeWalletSection({ standing_date: standingDate }),
        modifiers: {
          disabled: {
            before: new Date(),
          },
        },
      },
    }),
    [standing_date, onChangeWalletSection]
  );
  const mobileNumberInput = React.useMemo(
    () => ({
      defaultValue: wallet_mobile,
      onChange: ({ target: { value: walletMobile } }) =>
        onChangeWalletSection({ wallet_mobile: walletMobile }),
    }),
    [wallet_mobile, onChangeWalletSection]
  );
  return {
    dayPickerInput,
    mobileNumberInput,
  };
};

export const WalletSectionLayout = ({ dayPickerInput, mobileNumberInput }) => (
  <FlexColumn gap="5px">
    <Box>
      <Label>Period of standing instruction</Label>
      <Label>
        <Radio name="standingInstructionPeriod" defaultChecked />
        Monthly
      </Label>
    </Box>
    <DayPickerInput
      {...dayPickerInput}
      label="Date of standing instruction"
      inputProps={{
        variant: "border-bottom",
      }}
    />
    <InputWithIconAndLabel
      {...mobileNumberInput}
      type="text"
      label="Mobile Number Linked To Wallet"
      variant="border-bottom"
    />
  </FlexColumn>
);

WalletSectionLayout.propTypes = {
  dayPickerInput: PropTypes.shape({}),
  mobileNumberInput: PropTypes.shape({}),
};
WalletSectionLayout.defaultProps = {
  mobileNumberInput: {},
  dayPickerInput: {},
};

const WalletSection = (props) => {
  const { onChange = noop, wallet_mobile = "", standing_date = null } = props;
  const walletSection = useWalletSection({
    onChange,
    wallet_mobile,
    standing_date,
  });
  return <WalletSectionLayout {...walletSection} />;
};

WalletSection.propTypes = {
  wallet_mobile: PropTypes.string,
  standing_date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
};

WalletSection.defaultProps = {
  wallet_mobile: "",
  standing_date: new Date(),
};

export default React.memo(WalletSection);
