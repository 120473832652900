import React from "react";
import PropTypes from "prop-types";
import { Flex, Label } from "theme-ui";
import Radios from "../Radios";

/** Radios with label */
const RadiosWithLabel = ({ label, radiosWrapper: R, ...restProps }) => (
  <>
    <Label>{label}</Label>
    <R>
      <Radios {...restProps} />
    </R>
  </>
);

RadiosWithLabel.propTypes = {
  label: PropTypes.string,
  radiosWrapper: PropTypes.elementType,
};

RadiosWithLabel.defaultProps = {
  label: "",
  radiosWrapper: Flex,
};

export default React.memo(RadiosWithLabel);
