import { Link, Text } from "theme-ui";
import PropTypes from "prop-types";
import React from "react";
import { noop } from "sites-common/utils/lodash";
import {
  InputWithIconAndLabel,
  FlexColumn,
  DayPickerInput,
} from "../../index";
import { formatToIST } from "./utils";

export const NetBankingSectionLayout = ({
  donorBankInput,
  dayPickerInput,
  ifscCodeInput,
}) => (
  <FlexColumn gap="5px">
    <Text my="2" sx={{ textDecoration: "underline" }}>
      Information:
    </Text>
    <Text>
      The donor can set up a standing instruction through the donor bank. The
      details to be given to the donor bank to set up the recurring donation
      will be given once the donation details are filled.
    </Text>
    <Text>
      Once the standing instruction set up is done, please email the details to:
      <Link href="mailto: info.accounts@sahajmarg.org">
        info.accounts@sahajmarg.org
      </Link>
    </Text>

    {/* DONOR BANK */}
    <InputWithIconAndLabel
      {...donorBankInput}
      variant="border-bottom"
      type="text"
      label="Donor's Bank"
    />

    {/* IFSC CODE INPUT */}
    <InputWithIconAndLabel
      type="text"
      label="Dononr's IFSC Code"
      variant="border-bottom"
      {...ifscCodeInput}
    />

    {/* STANDING INSTRUCTIONS DATE */}
    <DayPickerInput
      {...dayPickerInput}
      label="Date of standing instruction"
      inputProps={{
        readOnly: true,
        variant: "border-bottom",
      }}
    />
  </FlexColumn>
);

NetBankingSectionLayout.propTypes = {
  donorBankInput: PropTypes.shape({}),
  dayPickerInput: PropTypes.shape({}),
  ifscCodeInput: PropTypes.shape({}),
};

NetBankingSectionLayout.defaultProps = {
  donorBankInput: {},
  dayPickerInput: {},
  ifscCodeInput: {},
};

const useNetBakingSection = (props) => {
  const {
    standing_date = null,
    donor_bank = "",
    donor_ifsc_code = "",
    onChange = noop,
  } = props;

  const onChangeNetBanking = React.useCallback(
    (update) => {
      onChange({
        standing_date,
        donor_bank,
        donor_ifsc_code,
        ...update,
      });
    },
    [standing_date, donor_bank, donor_ifsc_code, onChange]
  );

  const dayPickerInput = React.useMemo(
    () => ({
      value: standing_date,
      formatDate: formatToIST,
      inputProps: {
        readOnly: true,
      },
      dayPickerProps: {
        onDayClick: (standingDate) => onChangeNetBanking({ standingDate }),
        modifiers: {
          disabled: {
            before: new Date(),
          },
        },
      },
    }),
    [standing_date, onChangeNetBanking]
  );
  const ifscCodeInput = React.useMemo(
    () => ({
      defaultValue: donor_ifsc_code,
      onChange: ({ target: { value: donorIfscCode } }) => {
        onChangeNetBanking({ donor_ifsc_code: donorIfscCode });
      },
    }),
    [donor_ifsc_code, onChangeNetBanking]
  );
  const donorBankInput = React.useMemo(
    () => ({
      defaultValue: donor_bank,
      onChange: ({ target: { value: donorBank } }) =>
        onChangeNetBanking({ donor_bank: donorBank }),
    }),
    [donor_bank, onChangeNetBanking]
  );
  return {
    dayPickerInput,
    ifscCodeInput,
    donorBankInput,
  };
};

const NetBankingSection = (props) => {
  const {
    onChange = noop,
    standing_date = null,
    donor_bank = "",
    donor_ifsc_code = "",
  } = props;
  const netBankingSection = useNetBakingSection({
    standing_date,
    donor_bank,
    donor_ifsc_code,
    onChange,
  });
  return <NetBankingSectionLayout {...netBankingSection} />;
};

NetBankingSection.propTypes = {
  donor_ifsc_code: PropTypes.string,
  standing_date: PropTypes.instanceOf(Date),
  donor_bank: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

NetBankingSection.defaultProps = {
  donor_ifsc_code: "",
  standing_date: new Date(),
  donor_bank: "",
};

export default React.memo(NetBankingSection);
