/** @jsx jsx */
import { jsx, Box, Flex, useThemeUI } from "theme-ui";
import { useEffect, useState } from "react";
import { ToWords } from "to-words";
import { DynamicField } from "sites-common/components/DynamicField";
import PropTypes from "prop-types";
import CurrencySymbol from "../../../ui/CurrencySymbol";
import useCurrencyRates from "../../hooks/useCurrencyRates";

const toWords = new ToWords();

function AmountSelector({
  initialAmount,
  initialCurrency,
  currency: to,
  setAmount,
  country,
  autoFocusName,
}) {
  const { theme } = useThemeUI();
  const [from, setFrom] = useState(initialCurrency);
  const [fromAmount, setFromAmount] = useState(initialAmount);

  const { currencyOptions, rates } = useCurrencyRates(to);
  const conversionRate = rates[from];

  const toAmount =
    from === to
      ? fromAmount || 0
      : Math.round(((fromAmount || 0) * conversionRate).toFixed(0) * 1);

  const words = toWords.convert(toAmount, { currency: country === "in" });

  useEffect(() => {
    setFromAmount(initialAmount);
    setFrom(initialCurrency);
  }, [initialAmount, initialCurrency]);

  useEffect(() => {
    setAmount(toAmount);
  }, [toAmount]);

  const { bgBox } = theme.boxes || {};

  return (
    <div>
      <div sx={{ color: "secondary" }}>Amount</div>
      <Box sx={{ flex: "0 0 auto", color: "primary" }}>
        <Flex sx={{ alignItems: "center", ...bgBox }}>
          <DynamicField
            sx={{
              textAlign: "center",
              pl: 4,
              ...bgBox,
              fontSize: "64px",
              borderRight: "solid 1px",
              borderRightColor: "background",
            }}
            type="number"
            autoFocus={autoFocusName === "amount"}
            autoComplete="new-password"
            value={fromAmount || ""}
            onChange={setFromAmount}
            name="amount"
            label="Amount"
            placeholder="0"
          />
          <DynamicField
            sx={{ ...bgBox, mr: 3, width: "70px", cursor: "pointer" }}
            type="select"
            value={from}
            onChange={setFrom}
            name="from"
            options={currencyOptions}
          />
        </Flex>
        {from !== to && (
          <div
            sx={{
              mt: "1px",
              p: 2,
              ...bgBox,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Contribution Amount: <CurrencySymbol to={to} /> {toAmount} ({to})
          </div>
        )}
        <div
          sx={{
            mt: "1px",
            ...bgBox,
            maxWidth: "100%",
            textAlign: "center",
            p: 1,
            fontSize: "0.8rem",
          }}
        >
          {words}
        </div>
      </Box>
    </div>
  );
}

AmountSelector.propTypes = {
  initialAmount: PropTypes.number,
  initialCurrency: PropTypes.string,
  currency: PropTypes.string,
  setAmount: PropTypes.func.isRequired,
  country: PropTypes.string,
  autoFocusName: PropTypes.string,
};

AmountSelector.defaultProps = {
  initialAmount: 0,
  initialCurrency: "INR",
  currency: "INR",
  country: "in",
  autoFocusName: "",
};

export default AmountSelector;
