import { useCallback, useReducer } from "react";
import { set as lSet } from "../utils/lodash";

function reducer(state, action) {
  let ret;
  switch (action.type) {
    case "deleteKey":
      ret = { ...state };
      delete ret[action.k];
      return ret;
    case "merge":
      return { ...state, ...action.n };
    case "updateKVE":
      ret = { ...state };
      lSet(ret, action.k, action.v);
      Object.entries(action.e).map(([k, v]) => lSet(ret, k, v));
      return ret;

    case "set":
      return { ...action.n };
    default:
      throw new Error();
  }
}

function useMyState(d = {}) {
  const [s, dispatch] = useReducer(reducer, d);

  const reset = useCallback(() => dispatch({ type: "set", n: d }), [d]);
  const set = useCallback((n = {}) => dispatch({ type: "set", n }), []);
  const deleteKey = useCallback((k) => dispatch({ type: "deleteKey", k }), []);
  const merge = useCallback((n = {}) => dispatch({ type: "merge", n }), []);
  const updateKVE = useCallback(
    (v, k, e = {}) => dispatch({ type: "updateKVE", k, v, e }),
    []
  );

  return [s, { merge, updateKVE, set, deleteKey, reset }];
}
export default useMyState;
