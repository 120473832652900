import { Flex } from "theme-ui";
import React from "react";
import PropTypes from "prop-types";

const FlexColumn = ({ children, gap, sx, ...restProps }) => (
  <Flex
    sx={{
      flexDirection: "column",
      "> div": {
        marginY: gap,
      },
      ...sx,
    }}
    {...restProps}
  >
    {children}
  </Flex>
);

FlexColumn.propTypes = {
  children: PropTypes.any,
  gap: PropTypes.number,
  sx: PropTypes.object,
};

export default React.memo(FlexColumn);
