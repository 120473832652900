/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import PropTypes from "prop-types";
import useDonationConf from "../hooks/useDonationConf";
import { errorStrings } from "./restrictions";

const listStyle = {
  listStyleType: "circle",
  marginTop: "0.2em",
};

function ErrorsOrWarnings({ errors, warnings }) {
  const { theme } = useThemeUI();
  const { conf } = useDonationConf();

  return (
    <div>
      {errors && errors.length > 0 && (
        <ul sx={listStyle}>
          {errors &&
            errors.map((error) => (
              <li sx={theme.text?.invertedDanger} key={error}>
                <b sx={theme.text?.invertedDanger.liStyle}>-</b>{" "}
                {error.includes("customErrorMessage")
                  ? `${error.split(":")[1]}`
                  : errorStrings({ conf, error })}
              </li>
            ))}
        </ul>
      )}
      {warnings && warnings.length > 0 && (
        <ul sx={listStyle}>
          {warnings &&
            warnings.map((error) => (
              <li sx={theme.text?.invertedWarning} key={error}>
                {errorStrings({ conf, error })}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

ErrorsOrWarnings.propTypes = {
  errors: PropTypes.shape([]),
  warnings: PropTypes.shape([]),
};
ErrorsOrWarnings.defaultProps = {
  errors: [],
  warnings: [],
};

export default ErrorsOrWarnings;
