/** @jsx jsx */
import React, { useCallback, useEffect } from "react";
import { jsx, Flex, Box, Label, useThemeUI } from "theme-ui";
import PropTypes from "prop-types";
import {
  DynamicField as D,
  DynamicFieldStyle1 as DynamicField,
} from "sites-common/components/DynamicField";

const indiaOptions = [
  "Pancard",
  "Passport",
  "Aadhar Card",
  "Voter Id",
  "Driving License",
  "Ration Card",
];
const overseasOptions = [
  { name: "Pancard", label: "Indian Pancard" },
  { name: "Passport", label: "Passport No." },
];

const placeholders = {
  Pancard: "PAN number",
  Passport: "Type Passport No",
  "Aadhar Card": "Aadhar number",
  "Voter Id": "Voter Id",
  "Driving License": "Driving License",
  "Ration Card": "Ration Card No",
};

function TaxFields({
  autoFocusName,
  profile,
  setProfile,
  containerStyle,
  showOtherCitizen,
}) {
  const { theme } = useThemeUI();

  // hack to ensure backward compatibility with the way of storing PAN earlier.
  // this hack can be removed after sometime.
  useEffect(() => {
    if (!!profile.pan && !profile.identificationNumber) {
      setProfile(profile.pan, "identificationNumber", {
        identificationType: "Pancard",
      });
    }
  }, [profile]);

  // if the TaxFields is opened for entering PAN as part of warning pan, update the identificationType appropriately
  useEffect(() => {
    if (autoFocusName === "pan") {
      setProfile("Pancard", "identificationType");
    }
  }, []);

  const toggleIndian = useCallback(() => {
    setProfile(!profile.indian, "indian", {
      citizenship_id: !profile.indian ? 358 : 0,
      citizenship: !profile.indian ? "India" : "",
      identificationType: "Pancard",
    });
  }, [profile.indian]);

  useEffect(() => {
    if (!showOtherCitizen && !profile.indian) {
      toggleIndian();
    }
  }, [showOtherCitizen]);

  return (
    <div>
      <div sx={{ color: "secondary", mt: 4, mb: 2 }}>
        Tax Related Information
      </div>

      <Label
        sx={{
          paddingLeft: "6px",
          height: "1rem",
          fontSize: "0.8rem",
          fontWeight: "300",
          fontFamily: "body",
        }}
      >
        {" "}
        Citizenship{" "}
      </Label>

      <table sx={theme.boxes?.bgBox}>
        <tbody>
          <tr sx={{ height: "60px" }}>
            <td
              sx={{
                cursor: "pointer",
                minWidth: "120px",
                minHeight: "40px",
                fontSize: "1.3em",
              }}
            >
              <div
                role="button"
                tabIndex="0"
                onKeyDown={toggleIndian}
                onClick={toggleIndian}
              >
                <input
                  type="checkbox"
                  checked={profile.indian || false}
                  readOnly
                  sx={{
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                />
                <font sx={{ pb: 2 }}>&nbsp;India&nbsp;</font>
              </div>
            </td>
            <td sx={{ px: 2, width: "100%" }}>
              {showOtherCitizen ? (
                <React.Fragment>
                  <Label
                    sx={{
                      height: "1.1rem",
                      fontSize: "0.9rem",
                      fontWeight: "300",
                      fontFamily: "body",
                    }}
                  >
                    {" "}
                    {!profile.indian && "✓"} Other Country
                  </Label>
                  <D
                    name="citizenship_id"
                    type="country_id"
                    value={
                      profile.citizenship_id === 358
                        ? 0
                        : profile.citizenship_id
                    }
                    options={{ citizenship: "name" }}
                    onChange={(v, k, e) => {
                      const val = v || 0;
                      setProfile(val, k, {
                        ...e,
                        indian: false,
                        identificationType: "Passport",
                      });
                    }}
                    includeOnly={(r) => r.name !== "India"}
                    placeholder="Enter Other Country"
                  />
                </React.Fragment>
              ) : (
                <span sx={{ fontWeight: "bold" }}>
                  This donation is only for Indian Citizen
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </table>

      <Label
        sx={{
          mt: 3,
          paddingLeft: "6px",
          height: "1rem",
          fontSize: "0.8rem",
          fontWeight: "300",
          fontFamily: "body",
        }}
      >
        Select Id Type
      </Label>
      <Flex>
        <Box sx={{ paddingTop: "2px", mt: 1, mr: 3, width: "200px" }}>
          <DynamicField
            containerStyle={containerStyle}
            name="identificationType"
            type="select"
            hideLabel
            value={profile.identificationType}
            options={
              profile.citizenship_id !== 358 ? overseasOptions : indiaOptions
            }
            onChange={setProfile}
          />
        </Box>
        <Box>
          <DynamicField
            containerStyle={containerStyle}
            autoFocus={autoFocusName === "pan"}
            inputTransform={(r) => r.toUpperCase().trim()}
            placeholder={placeholders[profile.identificationType]}
            name="identificationNumber"
            icon="id-card-alt"
            value={profile.identificationNumber}
            onChange={setProfile}
            hideLabel
          />
        </Box>
      </Flex>
      <DynamicField
        containerStyle={containerStyle}
        name="memberId"
        label="Abhyasi ID / Member ID"
        icon="id-badge"
        inputTransform={(r) => r.toUpperCase().trim()}
        value={profile.memberId}
        onChange={setProfile}
      />
      {profile.citizenship_id !== 358 ? (
        <div sx={{ ...theme.text?.description, my: 3 }}>
          If you file your income in India, please enter India PAN Number for
          tax benefits. Otherwise, please enter the Passport number. If neither
          entered, the organization needs to pay 30% tax on the donation
          received.
        </div>
      ) : (
        <div sx={{ ...theme.text?.description, my: 3 }}>
          PAN Number is mandatory for income tax benefits under 80G. If you do
          not have PAN number, please enter any other ID. If no ID is entered,
          the organization needs to pay 30% tax on the donation received.
        </div>
      )}
    </div>
  );
}

TaxFields.propTypes = {
  autoFocusName: PropTypes.string,
  profile: PropTypes.shape({
    pan: PropTypes.string,
    indian: PropTypes.bool,
    citizenship_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    identificationType: PropTypes.string,
    identificationNumber: PropTypes.string,
    memberId: PropTypes.string,
  }).isRequired,
  setProfile: PropTypes.func.isRequired,
  containerStyle: PropTypes.shape({}),
  showOtherCitizen: PropTypes.bool,
};

TaxFields.defaultProps = {
  autoFocusName: "",
  containerStyle: {},
  showOtherCitizen: true,
};

export default TaxFields;
