/** @jsx jsx */
import { useMemo } from "react";
import { jsx, Button, useThemeUI, Container } from "theme-ui";

import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { usePersist } from "gatsby-plugin-hfn-profile/globals";

const OfflineDonation = ({ onClick }) => (
  <div>
    We thank you for the interest shown in donating to SMSF. The required bank
    details have been e-mailed to the given mail id. After the donation is made,
    Please email the information to
    <a
      sx={{
        color: "accent",
        textDecoration: "underline",
      }}
      href="mailto: info.accounts@sahajmarg.org"
    >
      {" "}
      info.accounts@sahajmarg.org{" "}
    </a>
    so that the donation receipt is generated and sent.
    <Button type="button" variant="buttons.variant1" onClick={onClick}>
      Go Back
    </Button>
  </div>
);

OfflineDonation.propTypes = {
  onClick: PropTypes.func.isRequired,
};
function PaymentResult({ closeModal, openModal, qp }) {
  const {
    status,
    type,
    txnId,
    currency,
    amount,
    refNo,
    trkId,
    npci_txn_id,
    digio_doc_id,
    message,
  } = qp;

  const ui = useMemo(
    () => ({
      refNo: refNo || digio_doc_id,
      txnId: txnId || npci_txn_id,
      trkId: trkId || digio_doc_id,
    }),
    [npci_txn_id, digio_doc_id, txnId, refNo, trkId]
  );

  const [trk] = usePersist("donation-trk", {});
  const { theme } = useThemeUI();

  if (!(status === "success" || status === "failure" || status === "cancel")) {
    return null;
  }
  function goBack() {
    /* Currently redirecting to the home page, as we don't have any navigation to other pages */
    // navigate(window.location.pathname);
    navigate("/");
    closeModal();
  }
  function retryPayment() {
    navigate("/");
    openModal();
  }

  if (status === "success" && type === "offline") {
    return <OfflineDonation onClick={() => goBack()} />;
  }

  if (status === "success") {
    return (
      <Container sx={{ maxWidth: "400px", p: 3, margin: "0 auto" }}>
        <h2>Thank you!</h2>
        {message ? (
          <div>{message}</div>
        ) : (
          <div>
            Your transaction has been processed successfully. Please save this
            page for your records. The donation receipt{" "}
            {currency === "INR" && <span>(80G)</span>} has been sent to your
            email.
          </div>
        )}
        <br />
        <div sx={theme.messages?.variant1}>
          <h3>Donation Information</h3>
          <div>
            <p>
              <b>Reference Number: </b>
              <span>{ui.refNo || "NA"}</span>
            </p>
            <p>
              <b>Transaction Id: </b>
              <span>{ui.txnId || "NA"}</span>
            </p>
            <p>
              <b>Tracking Id: </b>
              <span>{ui.trkId || "NA"}</span>
            </p>
            {amount && (
              <p>
                <b>Donation Received: </b>
                <span>
                  {" "}
                  {currency} {amount}{" "}
                </span>
              </p>
            )}
            <p>{trk[trkId]}</p>
          </div>
        </div>
        <br />
        <Button sx={{ ...theme.buttons?.variant1 }} onClick={() => goBack()}>
          Go Back
        </Button>
      </Container>
    );
  }

  if (status === "failure" || status === "cancel") {
    return (
      <Container sx={{ maxWidth: "320px", p: 3, margin: "0 auto" }}>
        {message ? (
          <h4>Failed!</h4>
        ) : (
          <h4> Transaction Failed! Donation did not succeed. </h4>
        )}
        <div
          sx={{
            ...theme.messages?.variant1,
          }}
        >
          <div>
            <small>Tracking Id: </small> {ui.trkId}
          </div>
          <br />
          {message ? (
            <div>{message.replace(/Failurenull/g, "Failed")}.</div>
          ) : (
            <div>
              Please check your bank/card account. If money is debited, it will
              get refunded back to your bank/card account.
            </div>
          )}
        </div>
        <br />
        <br />
        {message ? (
          <Button sx={{ ...theme.buttons?.variant1 }} onClick={() => goBack()}>
            Go Back
          </Button>
        ) : (
          <Button
            sx={{ ...theme.buttons?.variant1 }}
            onClick={() => retryPayment()}
          >
            Retry Payment
          </Button>
        )}
      </Container>
    );
  }
}

PaymentResult.propTypes = {
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  qp: PropTypes.objectOf(PropTypes.any),
};

PaymentResult.defaultProps = {
  closeModal: () => {},
  openModal: () => {},
  qp: {},
};

export default PaymentResult;
