/** @jsx jsx */
import React, { useCallback, useState } from "react";
import { jsx, Box, ThemeProvider } from "theme-ui";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import adaptTheme from "sites-common/gatsby-utils/adaptTheme";
import FormWithSuccessFailure from "sites-common/components/ui/FormWithSuccessFailure";
import Avatar from "gatsby-plugin-hfn-profile/auth/Avatar";
import { Modal } from "gatsby-plugin-hfn-profile/components/Modal";
import { PresetThemes } from "./shared";
import PaymentResult from "./PaymentResult";
import { AmountSelectors } from "./AmountSelectors";
import { donationConfProps } from "./DonationWidget/restrictions";
import DonationWidget from "./DonationWidget";
import useDonationConf from "./hooks/useDonationConf";

export const clickDonateBtn = "donate-btn-click";
export const loadDonateSuccessPage = "donate-successpage-load";
const noop = () => {};

const ModalTitleBar = ({
  status,
  closeModal,
  titleText,
  theme,
  noModal,
  signInOption,
}) => {
  const txnPage =
    status === "success" || status === "failure" || status === "cancel";
  return (
    <div sx={{ bg: "secondary", color: "background" }}>
      <table sx={{ height: "50px", width: "100%" }}>
        <tr>
          <td sx={{ p: 2 }}>
            {!txnPage && !noModal && (
              <span
                onClick={closeModal}
                onKeyDown={closeModal}
                role="button"
                tabIndex={0}
                sx={{ cursor: "pointer", mx: 2 }}
              >
                <FontAwesomeIcon icon="arrow-left" />{" "}
              </span>
            )}
            {status === "success" && <span>Donation Successful</span>}
            {status === "failure" && <span>Transaction Failed</span>}
            {status === "cancel" && <span>Transaction Cancelled</span>}
            {!txnPage && <span>{titleText}</span>}
          </td>
          <td sx={{ p: 2, minWidth: "50px", width: "50px" }} align="right">
            {signInOption && <Avatar theme={theme} />}
          </td>
        </tr>
      </table>
    </div>
  );
};

ModalTitleBar.propTypes = {
  status: PropTypes.oneOf(["success", "failure", "cancel", ""]),
  titleText: PropTypes.string,
  closeModal: PropTypes.func,
  theme: PropTypes.shape({}),
  noModal: PropTypes.bool,
  signInOption: PropTypes.bool,
};

ModalTitleBar.defaultProps = {
  status: "",
  titleText: "",
  closeModal: null,
  theme: {},
  noModal: false,
  signInOption: true,
};

const DonationComponent = ({
  layout,
  layoutProps,
  donationId,
  projectId,
  packageDescription,
  titleText,
  theme,
  colors,
  eventCallback,
  noModal,
  noModalTitle,
  paymentSuccessUrl,
  paymentFailureUrl,
  status,
  qp,
  currency,
  enableSignInOption,
}) => {
  const [donationAmount, setAmount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  useDonationConf();

  const theme1 = React.useMemo(() => {
    return adaptTheme({
      ...PresetThemes[theme],
      colors: {
        ...PresetThemes[theme].colors,
        ...colors,
      },
    });
  }, [colors, theme]);

  const closeModal = useCallback(() => {
    if (!noModal) {
      setShowModal(false);
    }
  }, [noModal]);
  const openModal = useCallback(() => {
    if (!noModal) {
      setShowModal(true);
    }
  }, [noModal]);

  const donationConfDetails = donationConfProps(donationId);

  const donationProps = Object.assign(donationConfDetails, {
    initialCurrency: currency || donationConfDetails.initialCurrency,
  });

  const renderTxnPage = () => (
    <PaymentResult closeModal={closeModal} openModal={openModal} qp={qp} />
  );
  const onLoadSuccess = () => eventCallback(loadDonateSuccessPage);

  if (noModal) {
    return (
      <ThemeProvider theme={theme1}>
        {!noModalTitle && (
          <ModalTitleBar
            status={status}
            closeModal={closeModal}
            titleText={titleText}
            theme={theme1}
            noModal
            signInOption={enableSignInOption}
          />
        )}
        <Box sx={{ px: 2, bg: "background" }}>
          <FormWithSuccessFailure
            renderFailure={renderTxnPage}
            renderSuccess={renderTxnPage}
            isSuccess={status === "success"}
            isFailure={status === "failure"}
            isCancel={status === "cancel"}
            onLoadSuccess={onLoadSuccess}
          >
            <DonationWidget
              formDonationId={donationId}
              initialAmount={donationAmount}
              projectId={projectId}
              packageDescription={packageDescription}
              paymentSuccessUrl={paymentSuccessUrl}
              paymentFailureUrl={paymentFailureUrl}
              enableSignInOption={enableSignInOption}
              {...donationProps}
            />
          </FormWithSuccessFailure>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <div>
      {(showModal ||
        status === "success" ||
        status === "failure" ||
        status === "cancel") && (
        <ThemeProvider theme={theme1}>
          <Modal
            position="mobile"
            title={
              <ModalTitleBar
                status={status}
                closeModal={closeModal}
                titleText={titleText}
                theme={theme1}
                signInOption={enableSignInOption}
              />
            }
          >
            <FormWithSuccessFailure
              renderFailure={renderTxnPage}
              renderSuccess={renderTxnPage}
              isSuccess={status === "success"}
              isFailure={status === "failure"}
              isCancel={status === "cancel"}
              onLoadSuccess={onLoadSuccess}
            >
              <DonationWidget
                formDonationId={donationId}
                initialAmount={donationAmount}
                projectId={projectId}
                packageDescription={packageDescription}
                paymentSuccessUrl={paymentSuccessUrl}
                paymentFailureUrl={paymentFailureUrl}
                enableSignInOption={enableSignInOption}
                {...donationProps}
              />
            </FormWithSuccessFailure>
          </Modal>
        </ThemeProvider>
      )}
      <AmountSelectors
        updateAmount={setAmount}
        showDonations={openModal}
        layoutProps={{ btnStyle: {}, inputStyle: {}, ...layoutProps }}
        layout={layout}
      />
    </div>
  );
};

DonationComponent.propTypes = {
  layout: PropTypes.string,
  layoutProps: PropTypes.shape({}),
  donationId: PropTypes.string,
  projectId: PropTypes.string,
  packageDescription: PropTypes.string,
  titleText: PropTypes.string,
  theme: PropTypes.string,
  colors: PropTypes.shape({}),
  eventCallback: PropTypes.func,
  noModal: PropTypes.bool,
  noModalTitle: PropTypes.bool,
  paymentSuccessUrl: PropTypes.string.isRequired,
  paymentFailureUrl: PropTypes.string.isRequired,
  status: PropTypes.string,
  qp: PropTypes.shape({}),
  currency: PropTypes.string,
  enableSignInOption: PropTypes.bool,
};

DonationComponent.defaultProps = {
  layout: "single_button",
  layoutProps: {},
  donationId: "9",
  projectId: "",
  packageDescription: "",
  titleText: "Choose a donation amount",
  theme: "bootstrap",
  colors: {},
  eventCallback: noop,
  noModal: false,
  noModalTitle: false,
  status: undefined,
  qp: {},
  currency: "",
  enableSignInOption: true,
};

export default DonationComponent;
