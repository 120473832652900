/** @jsx jsx */
import { jsx } from "theme-ui";
import { useCallback } from "react";
import PropTypes from "prop-types";
import { useAlert } from "../../alert";

function ConfirmButton({ title, message, onClick, ...restprops }) {
  const { showAlert } = useAlert();

  const setD = useCallback(() => {
    const alert = {
      title,
      message: message || "Are you Sure?",
      confirm_text: "Yes",
      cancel_text: "No",
      confirm_callback: onClick,
      is_blocking: true,
    };
    showAlert(alert);
  }, [onClick, message, showAlert, title]);

  return (
    <span
      role="button"
      tabIndex={0}
      style={{ cursor: "pointer" }}
      onClick={setD}
      onKeyDown={null}
      {...restprops}
    />
  );
}

ConfirmButton.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ConfirmButton.defaultProps = {
  message: "Are You Sure?",
};

export default ConfirmButton;
