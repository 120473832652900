import { v4 as uuidv4 } from "uuid";
import queryString from "query-string";
import postUrl from "sites-common/utils/postUrl";

import {
  getRecurringDonationPayloadDigio,
  getRecurringPayloadAuthorizeDotNet,
} from "./prepareRecurringPayload";

const getMandatoryEnv = require("sites-common/utils/getMandatoryEnv");

const customError = "Something went wrong, Please try again later!";
const { donationServiceConfig } = getMandatoryEnv(["donationServiceConfig"]);
// console.log(donationServiceConfig, "DSC");
const continueRecurringPaymentProcess = ({
  digioRedirectUrl,
  digioSuccessUri,
  digioJSON,
  monthlyRecurringDonationState,
  email_id,
  payload,
}) => {
  const { donation_type } = monthlyRecurringDonationState;
  const { mandate_id, id } = digioJSON.otherDetail;
  const isOnline = donation_type === "ONLINE";
  const isStatus = digioJSON.status === 1;

  // const isStatus1 = digioJSON.status === 1;
  // const isStatus0 = digioJSON.status === 0;
  const { citizenship } = payload.customerDetail;
  const isIndianCitizen = citizenship === "India";

  // ONLINE - with mandate-id
  if (id && mandate_id && isOnline) {
    const paymentGatewayURL = [
      `${digioRedirectUrl}${mandate_id}`,
      uuidv4(),
      `${email_id}?redirect_url=${digioSuccessUri}`,
    ].join("/");
    window.location.href = paymentGatewayURL;
    return;
  }
  // ONLINE - without mandate-id
  if (id && !mandate_id && isOnline && !isIndianCitizen && isStatus) {
    return;
    // overseas donations message (already handled)
  }
  // OFFLINE  - status-1
  if (!isOnline && isStatus) {
    const payloadStatus = {
      type: "offline",
      status: "success",
    };
    window.location.href = [
      window.location.href,
      `?${queryString.stringify(payloadStatus)}`,
    ].join("");
  }
  // OFFLINE - status-0
  if (!isOnline && !isStatus) {
    // form-fields error (this may not be required as it is already handled by profile)
  }
};

function createPaymentRequest(donation_data) {
  const {
    currency = "INR",
    name,
    email,
    address,
    city,
    country,
    state,
    postalCode,
    phone,
    citizenship,
    donation_id = "9",
    amount,
    paymentSuccessUrl,
    paymentFailureUrl,
    projects = [],
    packageInfo = "",
    identificationType = "",
    identificationNumber = "",
    memberId = "",
  } = donation_data;

  // console.log(postdata);
  const newData = {
    userProfile: {
      userId: "",
      firstName: name,
      lastName: "",
      emailAddress: email,
      phoneNumber: phone,
      addressLine1: address,
      addressLine2: "",
      city,
      state: state || "",
      postalCode,
      country,
      memberId,
      taxId:
        identificationNumber && identificationType === "Pancard"
          ? identificationNumber
          : "",
      citizenshipCountry: citizenship,
      role: null,
    },
    lineItems: [
      {
        id: "",
        donationItem: {
          id: donation_id,
          name: "",
          description: "",
          region: "",
          currency: "",
          billingAccountName: "",
          isActive: false,
        },
        currency,
        amount,
        recurringStartDate: "",
        recurringFrequency: "",
      },
    ],
    currency: null,
    amount: null,
    description: null,
    clientSuccessRedirectUrl: paymentSuccessUrl,
    clientFailureRedirectUrl: paymentFailureUrl,
    clientId: "me",
    extras: {
      projects,
      identityType: identificationType,
      identityNumber: identificationNumber,
      packageDetail: packageInfo,
    },
  };
  // console.log("POST DATA", postdata);
  const { url } = donationServiceConfig;
  // ...postdata, // firstName, lastName, email, phone, amount, currency, purpose, address, city, state, country, postalCode, citizenship, memberId, taxid,
  return {
    url,
    method: "POST",
    data: newData,
  };
}

// function getTechnicalError(err) {
//   return `Technical Error: ${err.message}. Please try again later.`;
// }

function submitDonationRequest(
  formData,
  setLoader,
  onSuccess,
  onFailure,
  monthlyRecurringDonationState = { isMonthlyRecurringDonation: false }
) {
  const { url, method, data } = createPaymentRequest(formData);
  setLoader(true);
  if (monthlyRecurringDonationState.isMonthlyRecurringDonation) {
    if (formData.currency === "USD") {
      const payload = getRecurringPayloadAuthorizeDotNet({
        monthlyRecurringDonationState,
        formData,
      });
      const { authorizeDotNetRecurringPreSubscribe } = donationServiceConfig;
      fetch(authorizeDotNetRecurringPreSubscribe, {
        method: "POST",
        headers: new Headers({ "Content-type": "application/json" }),
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (response.ok) {
            setLoader(false);
            response.json().then((json) => {
              const paymentGateway = new URL(json.paymentGatewayUrl);
              paymentGateway.host = window.location.host;
              window.location.href = paymentGateway;
              return null;
            });
          }
        })
        .catch(() => {
          setLoader(false);
          onFailure(`customErrorMessage: ${customError}`);
        });
    }
    if (formData.currency === "INR") {
      const payload = getRecurringDonationPayloadDigio({
        monthlyRecurringDonationState,
        formData,
      });

      const { digioApiUri, digioRedirectUrl, digioSuccessUri } =
        donationServiceConfig;

      fetch(digioApiUri, {
        method: "POST",
        headers: new Headers({ "Content-type": "application/json" }),
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((digioJSON) => {
          setLoader(false);
          if (digioJSON) {
            continueRecurringPaymentProcess({
              digioRedirectUrl,
              digioSuccessUri,
              digioJSON,
              monthlyRecurringDonationState,
              email_id: payload.customerDetail.emailAddress,
              payload,
            });
          } else {
            onFailure(
              `customErrorMessage: Server returned Invalid Response, Please try again later!`
            );
          }
        })
        .catch(() => {
          setLoader(false);
          onFailure(`customErrorMessage: ${customError}`);
        });
    }
  } else {
    fetch(url, {
      method,
      headers: new Headers({ "Content-type": "application/json" }),
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          setLoader(false);
          // console.log(response);
          // Site Maintenance message / error message
          response.json().then((json) => {
            if (json.error && json.message) {
              const failueUrl = new URL(data.clientFailureRedirectUrl);
              const queryParams = { status: "failure", message: json.message };
              failueUrl.search = new URLSearchParams(queryParams).toString();
              window.location.href = failueUrl;
              return;
            }
            // console.log(json.donation, "SDS");
            // the status was ok and there is a json body
            if (!!json && "paymentGatewayUrl" in json) {
              onSuccess(json);
              // console.log(json.paymentGatewayRequestMethod, json.paymentGatewayUrl,  "SSS");
              if (json.paymentGatewayRequestMethod === "POST") {
                postUrl(
                  json.paymentGatewayUrl,
                  json.paymentGatewayRequestParamMap,
                  "post",
                  "_top"
                );
              } else if (
                // Temporary fix to test get signOff from US treasurer in PrevProd
                // Redirecting Payment gateway to respective PrevProd url by changing host
                json.donation.currency === "USD" &&
                json.paymentGatewayUrl.includes("authorized-dot-net")
              ) {
                const paymentGateway = new URL(json.paymentGatewayUrl);
                paymentGateway.host = window.location.host;
                window.location.href = paymentGateway;
              } else window.top.location.href = json.paymentGatewayUrl;
              // return Promise.resolve(json);
            } else if (!!json && "msg" in json) {
              throw new Error(json.msg);
            } else {
              throw new Error("Server Connection Error. Please try again!");
            }
          });
        } else {
          setLoader(false);
          /* return */
          if ("statusText" in response && !!response.statusText) {
            onFailure(`customErrorMessage: ${customError}`);
          }
        }
      })
      .catch(() => {
        setLoader(false);
        onFailure(`customErrorMessage: ${customError}`);
      });
  }
}

export default submitDonationRequest;
