import { get } from "sites-common/utils/lodash";
import { v4 as uuidv4 } from "uuid";
import { usePersist } from "gatsby-plugin-hfn-profile/globals";

function useDonorProfiles(country) {
  const DONOR_PROFILES_KEY = "donor-profiles";
  const [relevantProfiles, setRelevantProfiles, { deleteDeepKey }] = usePersist(
    [DONOR_PROFILES_KEY, country],
    {}
  );

  const saveProfile = (profile) => {
    const uniqid = get(profile, "uniqid", `${uuidv4().substring(0, 6)}`);
    setRelevantProfiles({
      ...relevantProfiles,
      [uniqid]: { ...profile, uniqid },
    });
  };

  return {
    relevantProfiles,
    numProfiles: Object.keys(relevantProfiles).length,
    saveProfile,
    deleteProfile: (uniqid) => deleteDeepKey([uniqid]),
  };
}

export default useDonorProfiles;
