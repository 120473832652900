import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { selectorJson as S } from "./state/selectors";
import { fetchJsonIfNeeded as A } from "./state/actions";

export const useFetchJson = (key, url, init) => {
  const dispatch = useDispatch();
  const { isLoaded, isFetching, data, errorMessage } = useSelector((state) =>
    S(state, key)
  );

  useEffect(() => {
    if (!isFetching && !isLoaded && url && key) {
      dispatch(A(key, url, init));
    }
  }, [isFetching, isLoaded, key, url]);

  return {
    isLoaded,
    isFetching,
    data,
    errorMessage,
    refresh: () => dispatch(A(key, url, init, true)),
  };
};

export const selectorFetchJson = S;
export const actionFetchJson = A;
